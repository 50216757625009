import React, {Component} from "react";
import {Item, Table} from 'semantic-ui-react';
import {connect} from "react-redux";
import styled from "styled-components";
import Gift from "./Gift";
import {resetError, showError} from "../../../../actions";

const TableCell = styled(Table.Cell)`
`;
const TableCellCrossing = styled(TableCell)`
  background-color: papayawhip;
`;
const TableCellParticipate = styled(TableCell)`
  background-color: rgba(171, 251, 199, 0.21);
`;
const TableCellRecipient = styled(TableCell)`
  background-color: rgba(161, 161, 161, 0.22);
`;

class ListCell extends Component {

  getCellGifts(recipient, contributor) {
    try {
      if (!recipient && !contributor) {
        return this.getOrfans();
      }
      if (!contributor) {
        return this.getIdeasFor(recipient);
      }
      if (!recipient) {
        return this.getUnexpectedFor(contributor);
      }
      return this.getGifts(recipient, contributor);
    } catch (e) {
      this.props.showError(e);
      return [];
    }
  }

  getOrfans() {
    let gifts = [];
    for (let gift of Object.values(this.props.gifts)) {
      if (Object.keys(gift.getRecipients()).length === 0 && Object.keys(gift.getContributors()).length === 0) {
        gifts.push(gift);
      }
    }
    return gifts;
  }

  getIdeasFor(recipient) {
    let gifts = [];
    for (let gift of Object.values(this.props.gifts)) {
      if (Object.keys(gift.getContributors()).length === 0 || gift.hasRequireContribution()) {
        for (let r of Object.values(gift.getRecipients())) {
          if (recipient.getId() === r.getId()) {
            gifts.push(gift);
          }
        }
      }
    }
    return gifts;
  }

  getUnexpectedFor(contributor) {
    let gifts = [];
    for (let gift of Object.values(this.props.gifts)) {
      if (Object.keys(gift.getRecipients()).length === 0) {
        for (let c of Object.values(gift.getContributors())) {
          if (contributor.getId() === c.getId()) {
            gifts.push(gift);
          }
        }
      }
    }
    return gifts;
  }

  getGifts(recipient, contributor) {
    let gifts = [];
    for (let gift of Object.values(this.props.gifts)) {
      for (let r of Object.values(gift.getRecipients())) {
        for (let c of Object.values(gift.getContributors())) {
          if (contributor.getId() === c.getId() && recipient.getId() === r.getId()) {
            gifts.push(gift);
          }
        }
      }
    }
    return gifts;
  }

  render() {
    const {key, recipient, contributor} = this.props;

    let tag = undefined;
    if (recipient && contributor && recipient.getId() === contributor.getId()) {
      tag = TableCellCrossing;
    } else if (recipient && recipient.getId() === this.props.auth.userID) {
      tag = TableCellRecipient;
    } else if (contributor && contributor.getId() === this.props.auth.userID) {
      tag = TableCellParticipate;
    }

    let content = [];
    for (let gift of this.getCellGifts(recipient, contributor)) {
      content.push(<Gift key={key + '-' + gift.getId()} gift={gift} contributor={contributor}/>);
    }

    return this._renderCell(content, tag);
  }

  _renderCell(content, CellTag = TableCell, disabled = false) {
    return <CellTag disabled={disabled}><Item.Group divided>{content}</Item.Group></CellTag>;
  }
}

const mapStateToProps = state => {
  return {
    gifts: state.gifts,
    auth: state.auth
  }
};

export default connect(mapStateToProps, {resetError, showError})(ListCell);
