import React from 'react'
import {Divider} from "semantic-ui-react";

const Footer = () => (
  <div id="footer">
    <Divider/>
  </div>
);

export default Footer
