import {Menu} from "semantic-ui-react";
import {NavLink, useMatch, useResolvedPath} from "react-router-dom";

export default function MenuLink({ label, to }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink to={to}>
      <Menu.Item name={label} active={!!match}>{label}</Menu.Item>
    </NavLink>
  )
}
