import React, {Component} from 'react'
import {Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {hideSearch} from "../../../actions";
import Search from "./ModalContent";

class ModalSearch extends Component {

  handleClose = () => this.props.hideSearch();

  render() {
    const {search} = this.props;
    return (
      <Modal className="modalSearch" size="large" open={search.display} closeIcon={true} onClose={() => this.handleClose()}>
        <Search handleClose={this.handleClose}/>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideSearch: () => dispatch(hideSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSearch);
