import {USER_PASSWORD_RESET, USER_PASSWORD_TOKEN_GENERATED, USER_PASSWORD_UPDATED, USERS_LOADED} from "./constants";
import container from "../bootstrap";

import {showError} from './error';

// TODO move into events.js
export function getUsers(event) {
  return function (dispatch) {
    if (!event) {
      dispatch(showError("Impossible de charger les utilisateurs sans donner un évènement"));
    } else {
      return container.getApiClient().events().getUsers(
        event,
        function(users) {
          dispatch({
            type: USERS_LOADED,
            payload: users,
          });
        },
        function(error) {
          dispatch(showError("Impossible de charger les utilisateurs de cet évènement", error));
        }
      );
    }
  }
}

/**
 * @param {string} email
 * @param callback
 * @param callbackError
 * @return {function(*): *}
 */
export function generateNewPasswordToken(email, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().users().generateNewPasswordToken(
      email,
      function(users) {
        if (callback) {
          callback();
        }
        dispatch({
          type: USER_PASSWORD_TOKEN_GENERATED,
          payload: users,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de générer un code pour renouveler votre mot-de-passe", error));
      }
    );
  }
}

/**
 * @param {string} email
 * @param {string} token
 * @param callback
 * @param callbackError
 * @return {function(*): *}
 */
export function resetPassword(email, token, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().users().resetPassword(
      email,
      token,
      function(users) {
        if (callback) {
          callback();
        }
        dispatch({
          type: USER_PASSWORD_RESET,
          payload: users,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de ré-initialiser votre mot-de-passe", error));
      }
    );
  }
}

/**
 * @param {string} password
 * @param callback
 * @param callbackError
 * @return {function(*): *}
 */
export function updatePassword(password, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().users().updatePassword(
      password,
      function() {
        if (callback) {
          callback();
        }
        dispatch({
          type: USER_PASSWORD_UPDATED,
          // payload: user,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de mettre à jour votre mot-de-passe", error));
      }
    );
  }
}
