import React, {Component} from 'react'
import {Confirm, Label, List} from 'semantic-ui-react'
import {connect} from "react-redux";
import RemoveIcon from "./RemoveIcon";
import {deleteRecipient} from "../../../../actions";

class Recipient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteVisible: false,
    };
  }

  showConfirmDelete = () => {
    this.setState({confirmDeleteVisible: true});
  };

  hideConfirmDelete = () => {
    this.setState({confirmDeleteVisible: false});
  };

  handleConfirmDeleteCancel = () => {
    this.hideConfirmDelete();
  };

  handleConfirmDeleteOk = () => {
    const {recipient, gift} = this.props;
    this.props.deleteRecipient(gift, recipient, function() {
      this.hideConfirmDelete();
    }.bind(this), function() {
      this.hideConfirmDelete();
    }.bind(this));
  };

  render = () => {
    const {recipient} = this.props;

    let deleteIcon = '';
    if (this.props.gift.getCreator().getId() === this.props.auth.userID) {
      deleteIcon = <RemoveIcon link onClick={this.showConfirmDelete} name="remove user" color="red" />
    }

    let content = <>{deleteIcon}<Label>{recipient.getFirstname()}</Label></>;
    let confirmContent = <div className='content'>Voulez-vous supprimer <Label>{recipient.getFirstname()}</Label> comme destinataire de ce cadeau ?</div>;

    return <>
      <Confirm
        open={!!this.state.confirmDeleteVisible}
        header={"Suppression d'un destinataire"}
        content={confirmContent}
        cancelButton="Non"
        confirmButton="Oui"
        onCancel={this.handleConfirmDeleteCancel}
        onConfirm={this.handleConfirmDeleteOk}
        dimmer="blurring"
      />
      <List.Item content={content} />
    </>;
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift: state.gift,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteRecipient: (gift, recipient, callback, callbackError) => dispatch(deleteRecipient(gift, recipient, callback, callbackError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipient);
