import React from "react";
import {connect} from "react-redux";
import {resetPassword} from "../../../actions";
import {Field, reduxForm} from "redux-form";
import {Button, Form} from "semantic-ui-react";

class ResetPassword extends React.Component
{
  handleSubmit = (values) => {
    this.props.resetPassword(
      encodeURI(values.email),
      values.token,
      function () {
        this.props.handleResetDone();
      }.bind(this)
    );
  };

  render() {
    return (
      <>
        <p>Saisissez votre courriel et le code que vous venez de recevoir par courriel pour générer un nouveau mot-de-passe.
          <br/>Vous recevrez également le nouveau mot-de-passe par courriel.</p>
        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Form.Group widths='equal'>
            <Field
              component={Form.Input}
              label="Courriel"
              name="email"
              placeholder="Courriel"
              required={true}
            />
            <Field
              component={Form.Input}
              label="Code"
              name="token"
              placeholder="Token"
              required={true}
            />
          </Form.Group>
          <Button type="submit" primary className={"left floated"}>Générer un nouveau mot-de-passe</Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form.resetPasswordToken
  };
};

export default reduxForm({form: 'resetPasswordToken'})(connect(mapStateToProps, {resetPassword})(ResetPassword));
