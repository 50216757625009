export default class Config
{
  constructor()
  {
    this.apiUrl = process.env.REACT_APP_API_URL;

    if (!this.apiUrl) {
      throw new Error("Environment variable REACT_APP_API_URL must be defined")
    }
  }
}
