import React, {Component} from 'react'
import {Checkbox, Container, Divider, Grid, Header, Icon, List, Popup} from 'semantic-ui-react'
import {connect} from "react-redux";
import {createContribution, deleteContribution, contributeContribution, giftSetRequireContribution} from "../../../../actions";
import Actions from "./Contribution/Actions";
import Contributor from "./Contributor";
import Recipient from "./Recipient";
import ContributorAdd from "./ContributorAdd";
import RecipientAdd from "./RecipientAdd";


class Contribution extends Component {

  handleContributionCreate = () => {
    this.props.createContribution(this.props.gift, null, true);
  };

  handleContributionUpdate = (amount, accepted, callback) => {
    this.props.updateContribution(
      this.getContribution(this.props.gift),
      amount,
      accepted,
      callback
    );
  };

  handleContributionDelete = (callback) => {
    let contribution = this.getContribution(this.props.gift);
    this.props.deleteContribution(contribution);
    callback();
  };

  getContribution = (gift) => {
    for (let c of Object.values(gift.getContributions())) {
      if (c.getUser().getId() === this.props.auth.userID) {
        // As we have a partial object (main object is gift here), we complete it
        // We should not do it here ...
        c.gift = gift;
        return c;
      }
    }
    return null;
  };

  toggleRequireContribution = (gift) => {
    this.props.giftSetRequireContribution(gift, !gift.hasRequireContribution());
  }

  render() {
    const {gift} = this.props;
    let contribution = this.getContribution(gift);

    // Complete partial objects
    Object.entries(gift.getContributions()).map(([key, contribution]) =>
      contribution.gift = gift
    );

    let requireContributionTitle = gift.hasRequireContribution() ? "Ce cadeau n'a plus besoin de participation" : <>Ce cadeau à besoin de participation&nbsp;!</>;

    return (
      <>
        <Divider horizontal>
          <Header as='h4'><Icon name='users'/>Participant(s) / Destinataire(s)</Header>
        </Divider>
        <Container>
          <Popup content={requireContributionTitle} trigger={
            <Checkbox style={{position: "absolute"}} toggle checked={!!gift.hasRequireContribution()} onClick={() => this.toggleRequireContribution(gift)} />
          } />
          <Grid columns={2} relaxed>
            <Grid.Column width={8} textAlign='right'>
              <List>
                {Object.entries(gift.getContributions()).map(([key, contribution]) =>
                  <Contributor key={"contribution_"+contribution.getId()} contribution={contribution} />
                )}
                <ContributorAdd gift={gift}/>
              </List>
            </Grid.Column>
            <Grid.Column width={8} textAlign='left'>
              <List>
                {Object.entries(gift.getRecipients()).map(([key, recipient]) => (
                  <Recipient key={"recipient_"+recipient.getId()} gift={gift} recipient={recipient} />
                ))}
                <RecipientAdd gift={gift} />
              </List>
            </Grid.Column>
          </Grid>
          <Divider vertical/>
        </Container>
        {!!gift.hasRequireContribution() && <Divider horizontal><Header color="red" as='h4'>Participation demandée pour ce cadeau&nbsp;!</Header></Divider>}
        <Divider horizontal>
          <Header as='h4'><Icon name="envelope outline"/>Votre participation</Header>
        </Divider>
        <Actions participation={contribution}
                 onContributionCreate={this.handleContributionCreate}
                 onContributionUpdate={this.handleContributionUpdate}
                 onContributionDelete={this.handleContributionDelete}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    gift: state.gift,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createContribution: (gift, amount, isAccepted, isCollected) => dispatch(createContribution(gift, amount, isAccepted, isCollected)),
    updateContribution: (contribution, amount, isAccepted, callback) => dispatch(contributeContribution(contribution, amount, isAccepted, callback)),
    deleteContribution: (contribution) => dispatch(deleteContribution(contribution)),
    giftSetRequireContribution: (gift, requireContribution) => dispatch(giftSetRequireContribution(gift, requireContribution)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contribution);
