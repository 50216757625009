import React, {Component} from 'react'
import {Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {clearGift} from "../../../../actions";
import GiftModalContent from "./Content";

class GiftModal extends Component {

  handleClose = () => this.props.clearGift();

  render() {
    const {gift} = this.props;
    return (
      <Modal className="modalGift" open={gift !== null} closeIcon={true} onClose={() => this.handleClose()}>
        <GiftModalContent gift={gift} />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    gift: state.gift,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearGift: () => dispatch(clearGift()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftModal);
