import Comment from "./Comment";

class CommentFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'comment') {
      throw new Error("Given raw data not fit for comment DTO");
    }

    return new Comment(
      data.id,
      data.attributes.text,
      data.attributes.date,
      data.relationships && data.relationships.gift ? this.DTOBuilder.getRelationship(data.relationships.gift) : null,
      data.relationships && data.relationships.user ? this.DTOBuilder.getRelationship(data.relationships.user) : null,
      data.attributes.deletable
    );
  }
}

export default CommentFactory;
