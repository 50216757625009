import React from "react";
import {Grid} from "semantic-ui-react";

import List from "./Members/List";
import Create from "./Members/Create";
import {connect} from "react-redux";
import {resetError, setTitle, showError} from "../../actions";
import container from "../../bootstrap";

class Members extends React.Component {
  state = {
    members: null,
    selectedMember: null,
  };

  constructor(props) {
    super(props);
    container.getApiClient().users().getUsers(
      function (members) {
        this.props.resetError();
        let data = {};
        for (let member of Object.values(members)) {
          data[member.id] = member;
        }
        this.setState({
          members: data,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les événements", error);
      }.bind(this)
    );
  }

  updateStateMemberAdd(member) {
    this.setState(prevState => {
      let members = Object.assign({}, prevState.members);
      members[member.id] = member;
      return { members };
    })
  }

  updateStateMemberRemove(memberId) {
    this.setState(prevState => {
      let members = Object.assign({}, prevState.members);
      delete members[memberId];
      return { members };
    })
  }

  componentDidMount() {
    this.props.setTitle("Gérer les événements");
  }

  handleCreate = (firstname, lastname, email) => {
    container.getApiClient().users().create(
      firstname, lastname, email,
      function (member) {
        this.updateStateMemberAdd(member);
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de créer le membre", error);
      }.bind(this)
    );
  };

  handleDelete = (id) => {
    container.getApiClient().users().delete(
      id,
      function() {
        this.updateStateMemberRemove(id);
      }.bind(this),
      function(error) {
        this.props.showError("Impossible de supprimer le membre", error);
      }.bind(this)
    );
  };

  handleUpdate = (id, firstname, lastname, email) => {
    container.getApiClient().users().update(
      id, firstname, lastname, email,
      function(member) {
        this.updateStateMemberAdd(member);
      }.bind(this),
      function(error) {
        this.props.showError("Impossible de mettre à jour le membre", error);
      }.bind(this)
    );
  };

  handleSelect = (member) => {
    this.setState({
      selectedMember: member
    });
  };

  render() {
    return (
      <Grid columns={2} relaxed='very'>
        <Grid.Column>
          <List members={this.state.members}
                handleUpdate={this.handleUpdate}
                handleDelete={this.handleDelete}
                handleSelect={this.handleSelect}
          />
        </Grid.Column>
        <Grid.Column>
          <Create handleCreate={this.handleCreate}/>
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(null, {setTitle, resetError, showError})(Members);
