import {ERROR_SHOW, ERROR_RESET} from "./constants";

export function showError(message, exception) {
  return {
    type: ERROR_SHOW,
    message,
    exception
  }
}

export function resetError() {
  return {type: ERROR_RESET}
}
