import React from "react";

import SummaryEvent from "./Balance/SummaryEvent";
import {setTitle} from "../../actions";
import {connect} from "react-redux";

class Balance extends React.Component
{
  state = {
    currentEventId: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.event && props.event.getId() !== state.currentEventId) {
      return {
        currentEventId: props.event.getId(),
      };
    }
    return null;
  }
  componentDidMount() {
    this.props.setTitle(`Comptes "${this.props.event.getName()}"`);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.setTitle(`Comptes "${this.props.event.getName()}"`);
  }

  render() {
    return <SummaryEvent />
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
