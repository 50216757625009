import React, {Component} from "react";
import {Button, Table} from 'semantic-ui-react';
import {connect} from "react-redux";
import styled from "styled-components";
import ListCell from "./List/ListCell";
import CreateModal from "./Modal/CreateModal";

const TableGift = styled.div`
  max-width: 99vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow-x: auto;

  table {
    table-layout: fixed;
    margin: auto !important;
    width: auto !important;

    th {
    }

    th,
    td {
      height: 3em;
      width: 6em;
      padding: 2px !important;
    }
  }
  
`;
const TableRow = styled(Table.Row)`
  th {
    border-top: 1px solid rgba(34,36,38,.1);
  }
  :first-child th {
    border-top: none;
  }
`;
const TableHeader = styled(Table.Header)`
`;
const TableHeaderCell = styled(Table.HeaderCell)`
  font-weight: bold
  background-color: #f9fafb;
  text-align: center !important;
`;
const TableCellSeparator = styled(TableHeaderCell)`
  height: 1em !important;
  width: 1em !important;
  background-color: #f9fafb;
`;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createGiftModalVisible: false,
    };
  }

  showCreateGiftModal = () => {
    this.setState({createGiftModalVisible: true});
  };

  hideCreateGiftModal = () => {
    this.setState({createGiftModalVisible: false});
  };

  handleCreateGiftModalClose = () => {
    this.hideCreateGiftModal();
  };

  render() {
    const {users, gifts, auth} = this.props;
    if (!users || !gifts || !auth.userID) {
      return ""
    }

    let orderedUsers = [...Object.values(users)].sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : -1);

    return (
      <TableGift>
        <Table celled compact>
          <TableHeader key="header">
            <TableRow key="row-names">
              <TableHeaderCell key='cell-contributor-recipient'>
                <CreateModal
                  handleClose={this.handleCreateGiftModalClose}
                  open={!!this.state.createGiftModalVisible}
                />
                <Button icon="plus" color="green" onClick={this.showCreateGiftModal} title="Créer un nouveau cadeau" />
              </TableHeaderCell>
              <TableHeaderCell key='unexpected-gifts'>Sans destinataire</TableHeaderCell>
              <TableCellSeparator key='cell-separator' />
              {orderedUsers.map(user => (
                <TableHeaderCell key={"cell-header-" + user.getId()}>{user.getFirstname()}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>

          <Table.Body key='body'>
            <TableRow key={"row-ideas"}>
              <TableHeaderCell key={"cell-ideas"}>Idées</TableHeaderCell>
              <ListCell key="orfans" />
              <TableCellSeparator key='empty-names-reminder'/>
              {orderedUsers.map(recipient => (
                <ListCell key={"idea-" + recipient.getId()} recipient={recipient} />
              ))}
            </TableRow>

            <TableRow key="row-separator">
              <TableCellSeparator key='empty'/>
              {orderedUsers.map(user => (
                <TableCellSeparator key={"footer-" + user.getId()}/>
              ))}
              <TableCellSeparator key='empty-2'/>
              <TableCellSeparator key='empty-3'/>
            </TableRow>

            {orderedUsers.map(contributor => (
              <TableRow key={"row-user-" + contributor.getId()}>
                <TableHeaderCell key={"cell-contributor-" + contributor.getId()}>{contributor.getFirstname()}</TableHeaderCell>
                <ListCell key={"unexpected-" + contributor.getId()} contributor={contributor} />
                <TableCellSeparator key={"cell-separator-" + contributor.getId()} />
                {orderedUsers.map(recipient => (
                  <ListCell key={"recipient-" + recipient.getId() + "-contributor-" + contributor.getId()} recipient={recipient} contributor={contributor}/>
                ))}
              </TableRow>
            ))}
          </Table.Body>
        </Table>
      </TableGift>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    gifts: state.gifts,
    auth: state.auth
  }
};

export default connect(mapStateToProps)(List);
