import React, {Component} from 'react'
import {connect} from "react-redux";
import {giftUpdate} from "../../../../actions";
import {Icon, Button, TextArea, Form} from "semantic-ui-react";
import styled from "styled-components";
import { Anchorme } from 'react-anchorme';

const Description = styled.span`
  min-width: 0;
`;
const Empty = styled.i`
  color: grey;
`;
const IconEdit = styled(Icon)`
  float: right;
`;
const Buttons = styled.p`
  margin-top: .3em;
  float: right;
`;

class GiftDescription extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      editFieldShow: false,
      editFieldValue: props.gift.getDescription()
    };
  }

  resetSate = () => {
    this.setState({
      editFieldShow: false,
      editFieldValue: this.props.gift.getDescription()
    });
  };

  handleEditUpdate = (event, object) => {
    this.setState({
      editFieldValue: object.value
    });
  };

  handleEditShow = () => {
    this.setState({
      editFieldShow: true
    })
  };

  handleEditCancel = () => {
    this.resetSate();
  };

  handleEditValidate = () => {
    this.props.giftUpdate(
      this.props.gift,
      this.state.editFieldValue,
      (gift) => {
        this.setState({
          editFieldShow: false,
          editFieldValue: gift.getDescription()
        });
      },
      () => {
        this.resetSate();
      }
    );
  };

  render() {
    const {gift} = this.props;
    const {editFieldShow, editFieldValue} = this.state;

    let iconEdit = null;
    let validate = null;
    let cancel = null;
    let description = gift.getDescription() ? <Description><Anchorme target="_blank">{gift.getDescription()}</Anchorme></Description> : <Empty>Pas de description</Empty>;

    if (editFieldShow) {
      description = <Form><TextArea autoFocus value={editFieldValue} onChange={this.handleEditUpdate}/></Form>;
      validate = <Button icon="checkmark" inverted size="mini" color="green" title="Modifier" onClick={this.handleEditValidate}/>;
      cancel = <Button icon="delete" inverted size="mini" color="red" title="Annuler" onClick={this.handleEditCancel}/>;
    } else if (gift.getCreator().getId() === this.props.auth.userID) {
      iconEdit = <IconEdit name="pencil" color="blue" title="Modifier la description du cadeau" link onClick={this.handleEditShow}/>;
    }

    return <>{description} {iconEdit} <Buttons className="">{validate} {cancel}</Buttons></>;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    giftUpdate: (gift, description, cb, cbError) => dispatch(giftUpdate(gift, undefined, description, undefined, undefined, cb, cbError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftDescription);
