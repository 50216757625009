import React from "react";
import {Icon, Item, Label, List} from "semantic-ui-react";
import {getGift} from "../../../../actions";
import {connect} from "react-redux";

class Contribution extends React.Component
{
  render() {
    const {contribution} = this.props;
    const gift = contribution.getGift()

    let contributionLabel;
    if (contribution.isAccepted()) {
      if (!contribution.getAmount()) {
        contributionLabel = <Label color="red">Montant manquant</Label>;
      } else {
        contributionLabel = <Label color="blue">{contribution.getAmount()} <Icon name='euro' /></Label>;
      }
    } else {
      contributionLabel = <Label color="orange">En attente</Label>;
    }

    let priceLabel;
    if (gift.getPurchasePrice()) {
      priceLabel = <Label tag color='yellow'>{gift.getPurchasePrice()} <Icon name='euro' /></Label>
    } else {
      priceLabel = <Label tag color='yellow' style={{opacity: .5}}>?</Label>
    }
    return (
      <Item>
        <Item.Content>
          <Item.Header as="a" onClick={() => this.props.getGift(gift.getId())}>{gift.getName()}</Item.Header>
          <Item.Meta>
            <List horizontal>
              <List.Item key="price">{priceLabel}</List.Item>
              <List.Item key="contribution">{contributionLabel}</List.Item>
              {Object.values(gift.getRecipients()).map((recipient) =>
                <List.Item key={recipient.getId()}><Label>{recipient.getFirstname()}</Label></List.Item>
              )}
            </List>
          </Item.Meta>
        </Item.Content>
      </Item>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGift: (id) => dispatch(getGift(id)),
  };
};

export default connect(null, mapDispatchToProps)(Contribution);
