import React from 'react'
import {Card, CardContent, Divider, Icon} from "semantic-ui-react";
import {Button, Modal} from 'semantic-ui-react'
import SearchForm from "./SearchForm";
import {submit} from "redux-form";
import {connect} from "react-redux";
import container from "../../../bootstrap";
import {getGift, resetError, showError} from "../../../actions";
import Gift from "../../Pages/Gifts/List/Gift";

class ModalContent extends React.Component {
  state = {
    gifts: [],
    searchActive: false,
  }

  handleSearchSubmit = (values) => {
    return container.getApiClient().gifts().search(
      values.keyword,
      values.recipient,
      values.contributor,
      values.bought,
      function (gifts) {
        this.props.resetError();
        this.setState({
          gifts: [...Object.values(gifts)].sort((a, b) => a.getDateCreation() > b.getDateCreation() ? -1 : 1),
          searchActive: true,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les cadeaux", error);
      }.bind(this)
    )
  };

  dispatchToForm = () => {
    this.props.dispatchToForm();
  };

  render() {
    return (
      <>
        <Modal.Header>Rechercher un cadeau</Modal.Header>
        <Modal.Content image>
          <Icon name="search" size="huge"/>
          <SearchForm onSubmit={values => this.handleSearchSubmit(values)}/>
        </Modal.Content>
        {!!this.state.searchActive && <Divider horizontal>{this.state.gifts.length} cadeau(x) trouvé(s)</Divider>}
        <Modal.Content scrolling>
          {!!this.state.searchActive &&<>
          <Card.Group centered itemsPerRow={4}>
            {Object.values(this.state.gifts).map(gift => (
              <Card key={`search-${gift.getId()}`}>
                <CardContent><Gift gift={gift} /></CardContent>
              </Card>
            ))}
          </Card.Group>
        </>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Rechercher"
            labelPosition='right'
            icon='checkmark'
            onClick={this.dispatchToForm}
            positive
          />
          <Button color='black' onClick={() => this.props.handleClose()}>
            Annuler
          </Button>
        </Modal.Actions>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchToForm: () => dispatch(submit('search-gifts')),
    getGift: (id) => dispatch(getGift(id)),
    resetError: () => dispatch(resetError()),
    showError: (error, details) => dispatch(showError(error, details)),
  };
};

export default connect(null, mapDispatchToProps)(ModalContent);
