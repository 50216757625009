import {Navigate} from 'react-router-dom'
import {connect} from "react-redux";

const PrivateRoute = ({ component: RouteComponent, user, auth, role }) => {
  const userHasRequiredRole = !!(user && auth.roles.includes(role))

  if (user && userHasRequiredRole) {
    return <RouteComponent />
  }

  return <Navigate to="/login" />
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    auth: state.auth,
  }
};

export default connect(mapStateToProps)(PrivateRoute);
