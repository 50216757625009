import React from "react";
import {Navigate} from "react-router-dom";
import {logout} from "../../actions";
import {connect} from "react-redux";

class Logout extends React.Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Navigate to='/'/>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Logout);
