import React from "react";
import {Item, Input, Label, List, Container, Popup, Confirm, Button, Icon} from "semantic-ui-react";
import {collectContribution} from "../../../../actions";
import {connect} from "react-redux";

class GiftBoughtContribution extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      updatedAmount: undefined,
    };
  }

  handleUpdateAmount = (event) => {
    this.setState({ updatedAmount: event.target.value });
  };

  showConfirm = (event) => {
    this.setState({confirm: true});
    event.preventDefault();
    event.stopPropagation();
  };

  hideConfirm = () => {
    this.setState({confirm: false});
  };

  handleConfirmCancel = () => {
    this.hideConfirm();
  };

  handleConfirmOk = () => {
    const {contribution} = this.props;
    const {updatedAmount} = this.state;
    let amount, collected;

    if (updatedAmount) {
      amount = Number.parseFloat(updatedAmount)
    }
    // Collected takes the opposite value
    collected = !contribution.isCollected();

    this.props.updateContribution(contribution,
      amount, collected,
      () => {
        this.hideConfirm();
        this.setState({ updatedAmount: undefined });
      }
    );
  };

  render() {
    const {updatedAmount} = this.state;
    const {contribution} = this.props;

    let contributionConfirmContent, contributionConfirmTitle;
    let popupText, labelText, labelColor, inputText, inputColor;
    let gift = contribution.getGift();
    let originalAmount = contribution.getAmount() ? contribution.getAmount() : undefined;
    let amount = updatedAmount ? updatedAmount : originalAmount;

    if (contribution.isCollected()) {
      let detailAccepted;
      if (contribution.isAccepted() === true) {
        detailAccepted = 'acceptée par le participant'
      } else if (contribution.isAccepted() === false) {
        detailAccepted = 'refusée par le participant'
      } else {
        detailAccepted = 'en attente de l\'acceptation du participant'
      }
      popupText = "Vous avez déjà récupéré cette participation ("+detailAccepted+"). Cliquez sur la croix pour annuler si besoin.";
      labelColor = "green";
      labelText = "Reçue";
      inputColor = "orange";
      inputText = "✘";
      contributionConfirmTitle = "Annuler le signalement de participation";
      contributionConfirmContent = <Container className="content"><p>Vous aviez déjà signalé avoir récupéré la participation
        de <Label as='span' circular color='yellow'>{contribution.getAmount()} <Icon name='euro' /></Label> pour
        le cadeau <strong>{gift.getName()}</strong> de la part de <Label as='span'>{contribution.getUser().getFirstname()}</Label>.</p>
        <p>Voulez vous annuler ?</p></Container>;
    } else {
      inputColor = "blue";
      if (contribution.isAccepted() === true) {
        popupText = "Participation acceptée par le participant, cliquez sur le bouton pour signaler que vous l'avez reçue (vous pouvez changer le montant si besoin).";
        labelColor = "blue";
        labelText = "Acceptée";
        inputText = "✔";
        contributionConfirmTitle = "Signaler un remboursement de participation";
        if (amount === originalAmount) {
          contributionConfirmContent =
            <Container className="content"><p><Label as='span'>{contribution.getUser().getFirstname()}</Label> a accepté
              de participer pour <Label as='span' circular color='yellow'>{amount} <Icon name='euro' /></Label> au cadeau <strong>{gift.getName()}</strong>.</p>
              <p>Voulez-vous signaler que vous avez bien reçu ce montant ?</p></Container>;
        } else {
          contributionConfirmContent =
            <Container className="content"><p><Label as='span'>{contribution.getUser().getFirstname()}</Label> avait accepté
              de participer pour <Label as='span' circular color='yellow'>{(originalAmount ? originalAmount+' '+<Icon name='euro' /> : "indéfini")}</Label> au cadeau <strong>{gift.getName()}</strong>.</p>
              <p>Vous avez mis à jour ce montant à <Label as='span' circular color='yellow'>{amount} <Icon name='euro' /></Label>.</p>
              <p>Voulez-vous signaler la réception de ce nouveau montant ?</p></Container>;
        }
      } else if (contribution.isAccepted() === false) {
        popupText = "Participation refusée par le participant. Vous pouvez quand même signaler qu'il/elle vous a remboursé (et changer le montant si besoin).";
        labelColor = "red";
        labelText = "Refusée";
        inputText = "✔";
        contributionConfirmTitle = "Signaler un remboursement de participation refusée";
        let amountMention = "";
        if (amount !== originalAmount) {
          amountMention = <span> (le montant originel de la participation était <Label as='span' circular color='yellow'>{(originalAmount ? originalAmount+' €' : "indéfini")}</Label>)</span>
        }
        contributionConfirmContent =
          <Container className="content"><p><Label as='span'>{contribution.getUser().getFirstname()}</Label> <u>avait
            refusé</u> la participation au cadeau <strong>{gift.getName()}</strong>. Mais s'il/elle vous a
            remboursé le montant de <Label as='span' circular color='yellow'>{amount} <Icon name='euro' /></Label>,
            vous pouvez validez cette information ici{amountMention}.</p>

            <p>Voulez-vous signaler avoir reçu un remboursement ?</p></Container>;
      } else {
        popupText = "Participation non encore acceptée par le participant. S'il vous a déjà donné l'argent, vous pouvez quand même le signaler (et changer le montant si besoin).";
        labelColor = "orange";
        labelText = "En attente";
        inputText = "✔";
        contributionConfirmTitle = "Signaler un remboursement de participation non encore acceptée";
        let amountMention = "";
        if (amount !== originalAmount) {
          amountMention = <span> (le montant originel de la participation était <Label as='span' circular color='yellow'>{(originalAmount ? originalAmount+' €' : "indéfini")}</Label>)</span>
        }
        contributionConfirmContent =
          <Container className="content"><p><Label as='span'>{contribution.getUser().getFirstname()}</Label> <u>n'a
            pas encore accepté</u> de participer au cadeau <strong>{gift.getName()}</strong>. Mais s'il/elle vous a
            déjà remboursé le montant de <Label as='span' circular color='yellow'>{amount} <Icon name='euro' /></Label>,
            validez cette information ici{amountMention}.</p>

            <p>Voulez-vous signaler que vous avez reçu le remboursement ?</p></Container>;
      }
    }

    return <List.Item>
      {this.state.confirm && <Confirm
        open={true}
        header={contributionConfirmTitle}
        content={contributionConfirmContent}
        cancelButton="Non"
        confirmButton="Oui"
        onCancel={this.handleConfirmCancel}
        onConfirm={this.handleConfirmOk}
        dimmer="blurring"
      />}
      <Item.Content style={{lineHeight: "2.7em"}}>
        <Popup content={popupText} header={"Participation de " + contribution.getUser().getFirstname()} trigger={
          <Label color={labelColor}>{labelText}</Label>
        }/>
        <Input labelPosition='right' type='text' style={{float: "right"}}
               onChange={(event) => this.handleUpdateAmount(event)}
               value={amount}
               className="inputContribution"
        >
          <Label>{contribution.getUser().getFirstname()}</Label>
          <input/>
          <Label className="inline"><Icon name='euro' /></Label>
          <Button color={inputColor} type='submit'
                  onClick={(event) => this.showConfirm(event)}>{inputText}</Button>
        </Input>
      </Item.Content>
    </List.Item>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateContribution: (contribution, amount, isCollected, callback) => dispatch(collectContribution(contribution, amount, isCollected, callback)),
  };
};

export default connect(null, mapDispatchToProps)(GiftBoughtContribution);
