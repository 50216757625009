import {COMMENT_CREATED, COMMENT_DELETED, GIFT_LOADED} from "./constants";
import container from "../bootstrap";
import {reset} from 'redux-form';
import {showError} from "./error";

export function createComment(gift, text) {
  return function (dispatch) {
    return container.getApiClient().comments().create(
      gift,
      text,
      function(comment) {
        dispatch({
          type: COMMENT_CREATED,
          payload: comment,
        });
        dispatch(reset('comment'));
        container.getApiClient().gifts().getGift(gift.getId(),
          function(refreshedGift) {
            dispatch({
              type: GIFT_LOADED,
              payload: refreshedGift
            })
          },
          function(error) {
            dispatch(showError("Impossible de mettre à jour ce cadeau", error));
          }
        );
      },
      function(error) {
        dispatch(showError("Impossible de créer le commentaire", error));
      }
    );
  }
}

// export function getComment(id) {
//   return function (dispatch) {
//     return container.getApiClient().comments().get(
//       id,
//       function(comment) {
//         dispatch({
//           type: COMMENT_LOADED,
//           payload: comment,
//         });
//       },
//       function(error, details) {
//         dispatch(showError(error, details));
//       }
//     );
//   }
// }

export function deleteComment(id) {
  return function (dispatch) {
    return container.getApiClient().comments().delete(
      id,
      function() {
        dispatch({
          type: COMMENT_DELETED,
          payload: id,
        });
      },
      function(error) {
        dispatch(showError("Impossible de supprimer le commentaire", error));
      }
    );
  }
}
