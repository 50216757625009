import React from "react";

import {Item, Grid, Label, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {showError} from "../../../actions";
import GiftBought from "./Summary/GiftBought";
import Contribution from "./Summary/Contribution";

class Summary extends React.Component {

  render() {
    let giftBoughtContributions = [];
    let giftContributingContributions = [];

    // Extract the gifts from the global state gifts
    for (let gift of Object.values(this.props.gifts)) {
      // Extract gift the current user bought
      if (gift.getBuyer()?.getId() === this.props.userID) {
        giftBoughtContributions.push({
          gift: gift,
          contributions: gift.getContributions()
        });
      }
      // Extract gift the current user contribute to
      for (let contribution of Object.values(gift.getContributions())) {
        if (contribution.getUser().getId() === this.props.userID) {
          // Since the global state do not load more than 2 levels, the gift attribute is undefined
          contribution.gift = gift;
          giftContributingContributions.push(contribution);
        }
      }
    }

    let toCollect = 0, collected = 0, toPay = 0, paid = 0;
    let rowsBought = [];
    let rowsContributions = [];
    let usersBalance = {};
    let rowsUsersBalance = [];

    // Loop through gifts the current user bought
    for (const giftBoughtContribution of giftBoughtContributions) {
      for (const contribution of Object.values(giftBoughtContribution.contributions)) {
        let amount = contribution.getAmount() ? contribution.getAmount() : 0;
        toCollect += amount;
        let userId = contribution.getUser().getId();
        if (!usersBalance[userId]) {
          usersBalance[userId] = {
            id: userId,
            totalPromise: 0,
            totalPromiseCollected: 0,
            totalDue: 0,
            totalDueCollected: 0,
          };
        }
        usersBalance[userId].totalPromise += amount;
        if (contribution.isCollected()) {
          collected += amount;
          usersBalance[userId].totalPromiseCollected += amount;
        }
      }
      rowsBought.push(
        <GiftBought key={giftBoughtContribution.gift.getId()} gift={giftBoughtContribution.gift} contributions={giftBoughtContribution.contributions} />
      );
    }

    // Loop through the current user's contributions
    for (const [key, contribution] of Object.entries(giftContributingContributions)) {
      let amount = contribution.getAmount();
      toPay += amount;
      let userId = contribution.getGift().getBuyer()?.getId() || 0;
      if (!usersBalance[userId]) {
        usersBalance[userId] = {
          id: userId,
          totalPromise: 0,
          totalPromiseCollected: 0,
          totalDue: 0,
          totalDueCollected: 0,
        };
      }
      usersBalance[userId].totalDue += amount;
      if (contribution.isCollected()) {
        paid += amount;
        usersBalance[userId].totalDueCollected += amount;
      }
      rowsContributions.push(
        <Contribution key={key} contribution={contribution} />
      );
    }

    Object.values(usersBalance).forEach(userBalance => {
      let user = this.props.users[userBalance.id];
      let userName = user?.getFirstname() || "(Pas d'acheteur)";
      let balance = userBalance.totalPromise - userBalance.totalPromiseCollected - userBalance.totalDue + userBalance.totalDueCollected;
      let labelColor = 'green';
      if (balance > 0) {
        labelColor = 'red';
      }
      if (balance < 0) {
        labelColor = 'blue';
      }
      rowsUsersBalance.push(<Table.Row key={userBalance.id}>
        <Table.Cell><Label>{userName}</Label></Table.Cell>
        <Table.Cell>{userBalance.totalPromise !== 0 ? userBalance.totalPromise : '-'}</Table.Cell>
        <Table.Cell>{userBalance.totalPromiseCollected !== 0 ? userBalance.totalPromiseCollected : '-'}</Table.Cell>
        <Table.Cell>{userBalance.totalDue !== 0 ? userBalance.totalDue : '-'}</Table.Cell>
        <Table.Cell>{userBalance.totalDueCollected !== 0 ? userBalance.totalDueCollected : '-'}</Table.Cell>
        <Table.Cell><Label color={labelColor}>
          {balance}
        </Label></Table.Cell>
      </Table.Row>);
    });

    return (
      <Grid className="pageSummary" divided='vertically'>
        <Grid.Row>
          <Grid.Column width={2} />
          <Grid.Column width={11}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>On vous<br/>a promis</Table.HeaderCell>
                  <Table.HeaderCell>(payé)</Table.HeaderCell>
                  <Table.HeaderCell>Vous avez<br/>promis</Table.HeaderCell>
                  <Table.HeaderCell>(payé)</Table.HeaderCell>
                  <Table.HeaderCell>Solde</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rowsUsersBalance}
                <Table.Row>
                  <Table.Cell><Label ribbon>Totaux</Label></Table.Cell>
                  <Table.Cell><strong>{toCollect}</strong></Table.Cell>
                  <Table.Cell><strong>{collected}</strong></Table.Cell>
                  <Table.Cell><strong>{toPay}</strong></Table.Cell>
                  <Table.Cell><strong>{paid}</strong></Table.Cell>
                  <Table.Cell><strong>{toCollect-collected-toPay+paid}</strong></Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer><Table.Row><Table.HeaderCell colSpan='6'><strong>
                Avant de régler le solde, validez dans les tableaux ci-dessous que les participations et les achats soient à jour
              </strong></Table.HeaderCell></Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Vos participations ({Object.keys(giftContributingContributions).length} cadeaux)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Item.Group divided>{rowsContributions}</Item.Group>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={10}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Cadeaux achetés ({Object.keys(giftBoughtContributions).length} cadeaux)
                    <span style={{float: "right"}}>Participations</span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Item.Group divided>{rowsBought}</Item.Group>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showError: (message) => {dispatch(showError(message))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
