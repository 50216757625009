import React, {Component} from 'react'
import {
  Button,
  Header, Icon,
  Label,
  List,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import container from "../../../bootstrap";
import {connect} from "react-redux";
import {registerUser, resetError, showError, unregisterUser} from "../../../actions";

export class ManageParticipants extends Component {

  state = {
    users: null,
    selectedEventUsers: null,
  }

  constructor(props) {
    super(props);
    // Fetch all users at startup
    container.getApiClient().users().getUsers(
      function (users) {
        this.props.resetError();
        let data = {};
        for (let user of Object.values(users)) {
          data[user.id] = user;
        }
        this.setState({
          users: data,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les utilisateurs", error);
      }.bind(this)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.event && prevProps.event && this.props.event.id !== prevProps.event.id) ||
      (this.props.event && !prevProps.event)
    ) {
      this.updateSelectedEventUsers();
    }
  }

  updateSelectedEventUsers() {
    // Fetch selected event users
    container.getApiClient().events().getUsers(
      this.props.event,
      function (users) {
        this.props.resetError();
        let data = {};
        for (let user of Object.values(users)) {
          data[user.getId()] = user;
        }
        this.setState({
          selectedEventUsers: data,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les utilisateurs de cet évènement", error);
      }.bind(this)
    );
  }

  handleRegisterUser(event, user) {
    this.props.registerUser(event, user, () => {this.updateSelectedEventUsers()});
  }

  handleUnregisterUser(event, user) {
    this.props.unregisterUser(event, user, () => {this.updateSelectedEventUsers()});
  }

  render() {
    const {event} = this.props;

    let title = "Gestion des participants";
    let content;
    let list = [];
    let available = [];
    let remainingUsers = Object.assign({}, this.state.users);
    if (event && this.state.selectedEventUsers) {
      title += " - " + event.getName();
      for (let user of Object.values(this.state.selectedEventUsers).sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : -1)) {
        list.push(
          <List.Item key={user.getId()}>
            <Label>{user.getFirstname()}</Label>
            <Button onClick={() => {this.handleUnregisterUser(event, user)}} className={"ui right floated icon button orange"}><Icon name={"remove"}/></Button>
          </List.Item>
        );
        delete remainingUsers[user.getId()];
      }
      for (let user of Object.values(remainingUsers).sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : -1)) {
        available.push(
          <List.Item key={user.getId()}>
            <Label>{user.getFirstname()}</Label>
            <Button onClick={() => {this.handleRegisterUser(event, user)}} className={"ui right floated icon button green"}><Icon name={"plus"}/></Button>
          </List.Item>
        );
      }
      content = <>
        <Table celled>
          <TableHeader>
            <TableRow key="header">
              <TableHeaderCell>Disponibles</TableHeaderCell>
              <TableHeaderCell>Participants</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow key="participant-list" verticalAlign='top' textAlign='center'>
              <TableCell>
                <List>{available}</List>
              </TableCell>
              <TableCell>
                <List>{list}</List>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>;
    } else {
      content = <blockquote>Sélectionnez un événement pour choisir ses participants</blockquote>;
    }

    return <>
      <Header as='h3'>{title}</Header>
      {content}
    </>;
  }
}

export default connect(null, {resetError, showError, registerUser, unregisterUser})(ManageParticipants);
