import {SEARCH_DISPLAYED, SEARCH_HIDDEN} from "../actions/constants";

const initialState = {
  display: false
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case SEARCH_DISPLAYED:
      return {display: true};
    case SEARCH_HIDDEN:
      return {display: false};
    default:
      break;
  }
  return state;
}
