
export const COMMENT_CREATE = "COMMENT_CREATE";
export const COMMENT_CREATED = "COMMENT_CREATED";
export const COMMENT_DELETE = "COMMENT_DELETE";
export const COMMENT_DELETED = "COMMENT_DELETED";

export const GIFT_LOADED = "GIFT_LOADED";
export const GIFT_CLEAR = "GIFT_CLEAR";
export const GIFT_TRACKED = "GIFT_TRACKED";
export const GIFT_UN_TRACKED = "GIFT_UN_TRACKED";
export const GIFTS_LOADED = "GIFTS_LOADED";
export const GIFTS_CLEAR = "GIFTS_CLEAR";
export const GIFT_PURCHASE_CANCEL = "GIFT_PURCHASE_CANCEL";
export const GIFT_PURCHASE = "GIFT_PURCHASE";
export const GIFT_CREATED = "GIFT_CREATED";
export const GIFT_UPDATED = "GIFT_UPDATED";
export const GIFT_DELETED = "GIFT_DELETED";

export const GIFT_EVENT_BOUND = "GIFT_EVENT_BOUND";

export const ERROR_SHOW = "ERROR_SHOW";
export const ERROR_RESET = "ERROR_RESET";

export const EVENT_SELECTED = "EVENT_SELECTED";
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENT_USER_REGISTERED = "EVENT_USER_REGISTERED";
export const EVENT_USER_UNREGISTERED = "EVENT_USER_UNREGISTERED";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const API_REQUEST_START = "API_REQUEST_START";
export const API_REQUEST_END = "API_REQUEST_END";

export const PAGE_TITLE = "PAGE_TITLE";

export const CONTRIBUTION_CREATED = "CONTRIBUTION_CREATED";
export const CONTRIBUTION_UPDATED = "CONTRIBUTION_UPDATED";
export const CONTRIBUTION_DELETED = "CONTRIBUTION_DELETED";
export const CONTRIBUTION_CANDIDATE_CREATED = "CONTRIBUTION_CANDIDATE_CREATED";
export const CONTRIBUTION_CANDIDATE_DELETED = "CONTRIBUTION_CANDIDATE_DELETED";

export const RECIPIENT_ADDED = "RECIPIENT_ADDED";
export const RECIPIENT_REMOVED = "RECIPIENT_REMOVED";

export const USERS_LOADED = "USERS_LOADED";
export const USER_PASSWORD_TOKEN_GENERATED = "USER_PASSWORD_TOKEN_GENERATED";
export const USER_PASSWORD_RESET = "USER_PASSWORD_RESET";
export const USER_PASSWORD_UPDATED = "USER_PASSWORD_UPDATED";

export const SEARCH_DISPLAYED = "SEARCH_DISPLAYED";
export const SEARCH_HIDDEN = "SEARCH_HIDDEN";
