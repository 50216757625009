class Gift
{
  constructor(id, selfLink, name, description, priceObserved, purchasePrice, dateCreation, purchaseDate, purchaseEvent, buyer, creator, recipients, contributors, contributions, comments, dateLastCheck, tracked, deletable, requireContribution) {
    this.id = id;
    this.selfLink = selfLink;
    this.name = name;
    this.description = description;
    this.priceObserved = priceObserved;
    this.purchasePrice = purchasePrice;
    this.dateCreation = dateCreation;
    this.purchaseDate = purchaseDate;
    this.purchaseEvent = purchaseEvent;
    this.buyer = buyer;
    this.creator = creator;
    this.recipients = recipients || {};
    this.contributors = contributors || {};
    this.contributions = contributions || {};
    this.comments = comments || {};
    this.dateLastCheck = dateLastCheck;
    this.tracked = tracked;
    this.deletable = deletable;
    this.requireContribution = requireContribution;
  }

  getId = () => {
    return this.id;
  };
  getSelfLink = () => {
    return this.selfLink;
  };
  getName = () => {
    return this.name;
  };
  getDescription = () => {
    return this.description;
  };
  getPriceObserved = () => {
    return this.priceObserved;
  };
  getPurchasePrice = () => {
    return this.purchasePrice;
  };
  getDateCreation = () => {
    return this.dateCreation;
  };
  getPurchaseDate = () => {
    return this.purchaseDate;
  };
  /** @return {Event} */
  getPurchaseEvent = () => {
    return this.purchaseEvent;
  };
  /** @return {User} */
  getBuyer = () => {
    return this.buyer;
  };
  /** @return {User} */
  getCreator = () => {
    return this.creator;
  };
  /** @return {User[]} */
  getRecipients = () => {
    return this.recipients;
  };
  addRecipient = (recipient) => {
    this.recipients[recipient.getId()] = recipient;
  };
  removeRecipient = (recipientId) => {
    delete this.recipients[recipientId];
  };
  /** @return {User[]} */
  getContributors = () => {
    return this.contributors;
  };
  addContributor = (contributor) => {
    this.contributors[contributor.getId()] = contributor;
  };
  removeContributor = (contributorId) => {
    delete this.contributors[contributorId];
  };
  /** @return {Contribution[]} */
  getContributions = () => {
    return this.contributions;
  };
  addContribution = (contribution) => {
    this.contributions[contribution.getId()] = contribution;
  };
  removeContribution = (contributionId) => {
    delete this.contributions[contributionId];
  };
  /** @return {Comment[]} */
  getComments = () => {
    return this.comments;
  };
  addComment = (comment) => {
    this.comments[comment.getId()] = comment;
  };
  removeComment = (commentId) => {
    delete this.comments[commentId];
  };
  getDateLastCheck = () => {
    return this.dateLastCheck;
  };
  setDateLastCheck = (date) => {
    this.dateLastCheck = date;
  };
  isTracked = () => {
    return this.tracked;
  };
  setTracked = (tracked) => {
    this.tracked = tracked;
  };
  isDeletable = () => {
    return this.deletable;
  };
  hasRequireContribution = () => {
    return this.requireContribution;
  };
  setRequireContribution = (requireContribution) => {
    this.requireContribution = requireContribution;
  };
}

export default Gift;
