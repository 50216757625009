class Event
{
  constructor(id, selfLink, name, deletable, date) {
    this.id = id;
    this.selfLink = selfLink;
    this.name = name;
    this.deletable = deletable;
    this.date = date;
  }

  getId = () => {
    return this.id;
  };
  getSelfLink = () => {
    return this.selfLink;
  };
  getName = () => {
    return this.name;
  };
  getDate = () => {
    return this.date;
  };
  isDeletable = () => {
    return this.deletable;
  };
}

export default Event;
