import {
  RECIPIENT_ADDED, RECIPIENT_REMOVED
} from "./constants";
import container from "../bootstrap";
import {showError} from "./error";

/**
 * @param {Gift} gift
 * @param {User} user
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function createRecipient(gift, user, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().gifts().createRecipient(
      gift,
      user,
      function() {
        if (callback) {
          callback();
        }
        dispatch({
          type: RECIPIENT_ADDED,
          payload: {
            giftId: gift.getId(),
            user: user,
          },
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible d'ajouter le destinataire", error));
      }
    );
  }
}

/**
 * @param {Gift} gift
 * @param {User} user
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function deleteRecipient(gift, user, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().gifts().deleteRecipient(
      gift,
      user,
      function() {
        if (callback) {
          callback();
        }
        dispatch({
          type: RECIPIENT_REMOVED,
          payload: {
            giftId: gift.getId(),
            userId: user.getId(),
          },
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de supprimer le destinataire", error));
      }
    );
  }
}
