import React, {Component} from 'react'
import {Comment, Label} from 'semantic-ui-react'
import moment from "moment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import styled from "styled-components";
import { Anchorme } from 'react-anchorme';

const FieldEmpty = styled.i`
  color: grey;
`;
const CommentActions = styled(Comment.Actions)`
  float: right;
`;

class CommentList extends Component {
  render() {
    const {comments, handleDelete} = this.props;
    let commentNb = Object.entries(comments).length;
    return (
      <Comment.Group>
      {commentNb > 0 ? Object.entries(comments).map(([key,comment]) => (
        <Comment key={"comment_" + comment.getId()}>
          {/*<Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />*/}
          <Comment.Content>
            <Icon name="user"/>
            <Label>{comment.getUser().getFirstname()}</Label>
            <Comment.Metadata>
              <div>{moment.parseZone(comment.getDate()).format('DD/MM/YYYY à HH:mm')}</div>
            </Comment.Metadata>
            {comment.isDeletable() && comment.getUser().getId() === this.props.userID &&
              <CommentActions onClick={() => handleDelete(comment)}>
                <Comment.Action><Icon name="delete"/> Supprimer</Comment.Action>
              </CommentActions>
            }
            <Comment.Text>{comment.getText().split('\n').map((item, key) => {
              return <span key={key}><Anchorme target="_blank">{item}</Anchorme><br/></span>
            })}</Comment.Text>
          </Comment.Content>
        </Comment>
      )) : <FieldEmpty>Pas de commentaire</FieldEmpty>}
      </Comment.Group>
    );
  }
}

export default CommentList;
