import {
  GIFT_LOADED,
  GIFT_CLEAR,
  COMMENT_CREATED,
  COMMENT_DELETED,
  CONTRIBUTION_CREATED,
  CONTRIBUTION_UPDATED,
  CONTRIBUTION_DELETED,
  GIFT_TRACKED,
  GIFT_UN_TRACKED,
  GIFT_PURCHASE,
  GIFT_PURCHASE_CANCEL,
  CONTRIBUTION_CANDIDATE_CREATED,
  CONTRIBUTION_CANDIDATE_DELETED,
  RECIPIENT_REMOVED,
  RECIPIENT_ADDED,
  GIFT_CREATED,
  GIFT_UPDATED,
  GIFT_DELETED,
} from "../actions/constants";

const initialState = null;

export default function gift(state = initialState, action) {
  /** @var {Gift} gift */
  let gift = state;
  /** @var {Contribution} contribution */
  let contribution = null;
  let giftId;

  switch (action.type) {

    case GIFT_LOADED:
    case GIFT_PURCHASE:
    case GIFT_PURCHASE_CANCEL:
    case GIFT_CREATED:
    case GIFT_UPDATED:
      return action.payload;

    case GIFT_CLEAR:
    case GIFT_DELETED:
      return null;

    case COMMENT_CREATED:
      /** @var {Comment} comment */
      let comment = action.payload;
      if (comment.getGift() && gift.getId() === comment.getGift().getId()) {
        gift.addComment(comment);
      }
      return Object.assign({}, state, gift);

    case COMMENT_DELETED:
      let commentId = action.payload;
      gift.removeComment(commentId);
      return Object.assign({}, state, gift);

    case CONTRIBUTION_CREATED:
    case CONTRIBUTION_CANDIDATE_CREATED:
      contribution = action.payload;
      if (contribution.getGift() && gift.getId() === contribution.getGift().getId()) {
        gift.addContribution(contribution);
      }
      return Object.assign({}, state, gift);

    case CONTRIBUTION_UPDATED:
      if (!gift) {  // An update can be done without any gift currently displayed
        return state;
      }
      contribution = action.payload;
      if (contribution.getGift() && gift.getId() === contribution.getGift().getId()) {
        gift.removeContribution(contribution.getId());
        gift.addContribution(contribution);
      }
      return Object.assign({}, state, gift);

    case CONTRIBUTION_DELETED:
    case CONTRIBUTION_CANDIDATE_DELETED:
      let contributionId = action.payload.id;
      gift.removeContribution(contributionId);
      return Object.assign({}, state, gift);

    case RECIPIENT_REMOVED:
      gift.removeRecipient(action.payload.userId);
      return Object.assign({}, state, gift);

    case RECIPIENT_ADDED:
      gift.addRecipient(action.payload.user);
      return Object.assign({}, state, gift);

    case GIFT_TRACKED:
    case GIFT_UN_TRACKED:
      giftId = action.payload;
      if (gift.getId() === giftId) {
        gift.setTracked(action.type === GIFT_TRACKED);
        return Object.assign({}, state, gift);
      }
      break;

    default:
  }

  return state;
}
