import {
  COMMENT_CREATED,
  COMMENT_DELETED,
  CONTRIBUTION_CANDIDATE_CREATED,
  CONTRIBUTION_CANDIDATE_DELETED,
  CONTRIBUTION_CREATED,
  CONTRIBUTION_DELETED,
  CONTRIBUTION_UPDATED,
  GIFT_CREATED,
  GIFT_UPDATED,
  GIFT_LOADED,
  GIFT_PURCHASE,
  GIFT_PURCHASE_CANCEL,
  GIFT_TRACKED,
  GIFT_UN_TRACKED,
  GIFT_DELETED,
  GIFTS_CLEAR,
  GIFTS_LOADED,
  RECIPIENT_ADDED,
  RECIPIENT_REMOVED,
} from "../actions/constants";
import _ from "lodash";

const initialState = {};

export default function gifts(state = initialState, action)
{
  let gifts = state;
  let gift;
  let contribution;
  let giftId;
  let userId;

  switch (action.type) {

    case GIFT_CREATED:
      gift = action.payload;
      if (gifts) {  // Not sure why but gifts can be null...
        gifts[gift.getId()] = gift;
      }
      return _.cloneDeep(gifts);

    case GIFT_UPDATED:
    case GIFT_LOADED:
    case GIFT_PURCHASE:
    case GIFT_PURCHASE_CANCEL:
      gift = action.payload;
      if (gifts && gifts[gift.getId()]) {  // Not sure why but gifts can be null...
        gifts[gift.getId()] = gift;
      }
      return _.cloneDeep(gifts);

    case GIFTS_LOADED:
      return action.payload;

    case GIFT_DELETED:
      giftId = action.payload;
      delete gifts[giftId];
      return _.cloneDeep(gifts);

    case GIFTS_CLEAR:
      return null;

    case COMMENT_CREATED:
      let comment = action.payload;
      if (comment.getGift()) {
        for (let gift of Object.values(gifts)) {
          if (comment.getGift() && gift.getId() === comment.getGift().getId()) {
            gift.addComment(comment);
          }
        }
      }
      return _.cloneDeep(gifts);

    case COMMENT_DELETED:
      let commentId = action.payload;
      for (let gift of Object.values(gifts)) {
        if (gift.getComments()) for (let comment of Object.values(gift.getComments())) {
          if (comment.getId() === commentId) {
            gift.removeComment(commentId);
          }
        }
      }
      return _.cloneDeep(gifts);

    case CONTRIBUTION_CREATED:
    case CONTRIBUTION_CANDIDATE_CREATED:
      contribution = action.payload;
      if (contribution.getGift()) {
        for (let gift of Object.values(gifts)) {
          if (contribution.getGift() && gift.getId() === contribution.getGift().getId()) {
            gift.addContribution(contribution);
            gift.addContributor(contribution.getUser());
          }
        }
      }
      return _.cloneDeep(gifts);

    case CONTRIBUTION_UPDATED:
      contribution = action.payload;
      if (contribution.getGift()) {
        for (let gift of Object.values(gifts)) {
          if (contribution.getGift() && gift.getId() === contribution.getGift().getId()) {
            gift.removeContribution(contribution.getId());
            gift.addContribution(contribution);
            gift.removeContributor(contribution.getUser());
            gift.addContributor(contribution.getUser());
          }
        }
      }
      return _.cloneDeep(gifts);

    case CONTRIBUTION_DELETED:
    case CONTRIBUTION_CANDIDATE_DELETED:
      let contributionId = action.payload.id;
      giftId = action.payload.giftId;
      userId = action.payload.userId;
      if (gifts[giftId].getContributions()) for (let contribution of Object.values(gifts[giftId].getContributions())) {
        if (contribution.getId() === contributionId) {
          gifts[giftId].removeContribution(contributionId);
        }
      }
      if (gifts[giftId].getContributors()) for (let contributor of Object.values(gifts[giftId].getContributors())) {
        if (contributor.getId() === userId) {
          gifts[giftId].removeContributor(userId);
        }
      }
      return _.cloneDeep(gifts);

    case RECIPIENT_REMOVED:
      giftId = action.payload.giftId;
      userId = action.payload.userId;
      if (gifts[giftId].getRecipients()) for (let recipient of Object.values(gifts[giftId].getRecipients())) {
        if (recipient.getId() === userId) {
          gifts[giftId].removeRecipient(userId);
        }
      }
      return _.cloneDeep(gifts);

    case RECIPIENT_ADDED:
      giftId = action.payload.giftId;
      let user = action.payload.user;
      if (gifts[giftId]) {
        gifts[giftId].addRecipient(user);
      }
      return _.cloneDeep(gifts);

    case GIFT_TRACKED:
    case GIFT_UN_TRACKED:
      giftId = action.payload;
      for (let gift of Object.values(gifts)) {
        if (gift.getId() === giftId) {
          gift.setTracked(action.type === GIFT_TRACKED);
          return _.cloneDeep(gifts);
        }
      }
      break;

    default:
  }
  return state;
}
