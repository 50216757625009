import React, {Component} from 'react'
import {Button, Confirm, Form} from "semantic-ui-react";
import {Field, reduxForm, initialize} from "redux-form";

import NumberInput from 'semantic-ui-react-numberinput';
import {connect} from "react-redux";
import Pending from "./Pending";
import Contribute from "./Contribute";

class Actions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.participation ? this.props.participation.getAmount() : null,
      accepted: this.props.participation ? this.props.participation.isAccepted() : undefined,
      confirmDeleteVisible: false,
    };
  }

  handleAmountChange = (amount) => {
    this.setState({
      amount: amount
    }, () => {
      // Callback the reduxForm initial change callback
      this.props.change("participation", amount);
    });
  };

  resetParticipation = (event) => {
    this.setState({
      amount: this.props.participation ? this.props.participation.getAmount() : null,
      accepted: this.props.participation ? this.props.participation.isAccepted() : undefined,
    }, () => {
      // Callback the reduxForm initial reset callback
      this.props.reset();
    });
    event.preventDefault();
    event.stopPropagation();
  };

  showConfirmDelete = (event) => {
    this.setState({confirmDeleteVisible: true});
    event.preventDefault();
    event.stopPropagation();
  };

  hideConfirmDelete = () => {
    this.setState({confirmDeleteVisible: false});
  };

  handleConfirmDeleteCancel = () => {
    this.hideConfirmDelete();
  };

  handleConfirmDeleteOk = () => {
    // does not work ...
    this.props.handleSubmit(this.props.onContributionDelete);
    // direct call to delete function
    this.props.onContributionDelete(function() {
      this.setState({amount: null, accepted: undefined}, () => {
        this.hideConfirmDelete();
      });
    }.bind(this));
  };

  handleContributionUpdate = () => {
    let newAccepted = this.props.participation.accepted !== this.state.accepted ? this.props.participation.accepted : undefined;
    this.props.onContributionUpdate(Number(this.state.amount), newAccepted, function() {
      this.setState({
        accepted: this.props.participation.accepted,
      }, () => {
        this.props.resetFormWith(this.state);
      });
    }.bind(this));
  };

  renderUpdateContribution = () => {
    const {pristine, submitting} = this.props;

    return (
      <Form.Group inline>
        <label style={{margin: 0}}>Participation</label>
        <Field
          name="participation"
          className="ui input numberInput"
          props={{
            value: String(this.state.amount ? this.state.amount : ''),
            allowEmptyValue: true,
            onChange: this.handleAmountChange,
            placeholder: "Participation"
          }}
          component={NumberInput}
        />
        <Form>
          <Button.Group floated='right'>
            <Form.Button onClick={this.handleContributionUpdate}
                         type="button" size="mini" disabled={pristine || submitting} color="green"
                         title="Modifier la participation">✔</Form.Button>
            <Button.Or text='ou'/>
            <Form.Button size="small" disabled={pristine || submitting} color="orange" title="Annuler le changement"
                         onClick={this.resetParticipation}>✘</Form.Button>
            <Button.Or text='ou'/>
            <Form.Button onClick={(event) => this.showConfirmDelete(event)} type="submit" size="small"
                         disabled={submitting} color="red"
                         title="Supprimer la participation">Ø</Form.Button>
            <Confirm
              open={!!this.state.confirmDeleteVisible}
              header={"Suppression de la participation"}
              content={"Voulez-vous supprimer votre participation à ce cadeau ?"}
              cancelButton="Non"
              confirmButton="Oui"
              onCancel={this.handleConfirmDeleteCancel}
              onConfirm={this.handleConfirmDeleteOk}
              dimmer="blurring"
            />
          </Button.Group>
        </Form>
      </Form.Group>
    );
  };

  render() {
    let contribution = this.props.participation;

    if (contribution) {
      if (contribution.isAccepted() === null) {
        return (<Pending contribution={contribution} onContributionUpdate={this.props.onContributionUpdate}/>);
      } else {
        return this.renderUpdateContribution();
      }
    } else {
      return (<Contribute onContributionCreate={this.props.onContributionCreate}/>);
    }
  }
}


const mapDispatchToProps = dispatch => {
  return {
    resetFormWith: (state) => dispatch(initialize('updateParticipation', state)),
  };
};

export default reduxForm({form: 'updateParticipation'})(connect(null, mapDispatchToProps)(Actions));
