import React, {Component} from 'react'
import {Button, Form} from 'semantic-ui-react'
import {Field, reduxForm} from "redux-form";
import styled from "styled-components";

const CommentButton = styled(Button)`
  float: right;
  margin-top: 1em !important;
`;

class CommentForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Form reply onSubmit={handleSubmit}>
        <Field
          component="textarea"
          id="text"
          name="text"
          required={true}
          placeholder="Commentaire"
          type="text"
          style={{minHeight: "3em", height: "3em"}}
        />
        <CommentButton content='Ajouter un commentaire' labelPosition='left' icon='comment' primary />
      </Form>
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: 'comment'
})(CommentForm);
