class Contribution
{
  constructor(id, gift, user, accepted, amount, collected, date) {
    this.id = id;
    this.gift = gift;
    this.user = user;
    this.accepted = accepted;
    this.amount = amount;
    this.collected = collected;
    this.date = date;
  }

  getId = () => {
    return this.id;
  };
  // getSelfLink = () => {
  //   return this.selfLink;
  // };
  /** @return {Gift} */
  getGift = () => {
    return this.gift;
  };
  /** @return {User} */
  getUser = () => {
    return this.user;
  };
  isAccepted = () => {
    return this.accepted;
  };
  getAmount = () => {
    return this.amount;
  };
  isCollected = () => {
    return this.collected;
  };
  getDate = () => {
    return this.date;
  };
}

export default Contribution;
