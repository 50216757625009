import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {Form, Header} from "semantic-ui-react";
import DatePicker from 'react-widgets/DatePicker';

export class Create extends Component {

  handleSubmit = (values) => {
    this.props.handleCreate(values.name, values.date);
  };

  render() {
    const {pristine, reset, submitting} = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
        <Header as='h3'>Créer un nouvel événement</Header>
        <Form.Group>
          <Form.Field
            id="name"
            name="name"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Nom"
            label="Nom de l'événement"
            control={Field}
          />
          <Form.Field className="form-control" required={true}>
            <label>Date de l'événement</label>
            <Field
              id="date"
              name="date"
              required={true}
              type="input"
              placeholder="Date"
              label="Date de l'événement"
              showTime={false}
              component={this.renderDatePicker}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Button primary disabled={pristine || submitting}>
            Créer
          </Form.Button>
          <Form.Button type="button" disabled={pristine || submitting} onClick={reset}>
            Annuler
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }

  renderDatePicker = ({input: {onChange, value}, showTime}) =>
    <DatePicker
      onChange={onChange}
      format="DD MMM YYYY"
      includeTime={showTime}
      value={!value ? null : new Date(value)}
    />;
}

export default reduxForm({
  form: 'create-event'
})(Create);
