import {EVENT_SELECTED, EVENT_USER_REGISTERED, EVENT_USER_UNREGISTERED, EVENTS_LOADED, GIFTS_CLEAR} from "./constants";
import container from "../bootstrap";

import {showError} from './error';

// TODO move into users.js
export function getEvents() {
  return function (dispatch) {
    return container.getApiClient().users().getEvents(
      function(events) {
        dispatch({
          type: EVENTS_LOADED,
          payload: events,
        });
      },
      function(error) {
        dispatch(showError("Impossible de charger les événements", error));
      }
    );
  }
}

export function selectEvent(event) {
  return function(dispatch) {
    dispatch({
      type: GIFTS_CLEAR,
    });
    dispatch({
      type: EVENT_SELECTED,
      event
    });
  }
}

export function registerUser(event, user, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().events().register(
      event,
      user,
      function() {
        if (callback) {
          callback();
        }
        dispatch({
          type: EVENT_USER_REGISTERED,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Erreur lors l'inscription de l'utilisateur à cet événement", error));
      }
    );
  }
}

export function unregisterUser(event, user, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().events().unregister(
      event,
      user,
      function() {
        if (callback) {
          callback();
        }
        dispatch({
          type: EVENT_USER_UNREGISTERED,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Erreur lors de la désinscription de l'utilisateur à cet événement", error));
      }
    );
  }
}
