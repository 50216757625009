import Role from "./Role";

class RoleFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'role') {
      throw new Error("Given raw data not fit for role DTO");
    }
    return new Role(data.id);
  }
}

export default RoleFactory;
