import {SEARCH_DISPLAYED, SEARCH_HIDDEN} from "./constants";

export function displaySearch() {
  return {
    type: SEARCH_DISPLAYED,
  }
}

export function hideSearch() {
  return {
    type: SEARCH_HIDDEN,
  }
}

export function toggleSearch() {
  return function (dispatch, getState) {
    if (getState().search.display) {
      return dispatch(hideSearch());
    } else {
      return dispatch(displaySearch());
    }
  }
}
