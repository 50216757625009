class Comment
{
  constructor(id, text, date, gift, user, deletable) {
    this.id = id;
    this.text = text;
    this.date = date;
    this.gift = gift;
    this.user = user;
    this.deletable = deletable;
  }

  getId = () => {
    return this.id;
  };
  // getSelfLink = () => {
  //   return this.selfLink;
  // };
  getText = () => {
    return this.text;
  };
  getDate = () => {
    return this.date;
  };
  isDeletable = () => {
    return this.deletable;
  };
  /** @return {Gift} */
  getGift = () => {
    return this.gift;
  };
  /** @return {User} */
  getUser = () => {
    return this.user;
  };
}

export default Comment;
