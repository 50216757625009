import React, {Component} from 'react'
import {Icon} from 'semantic-ui-react'
import GiftEventsChose from "./GiftEventsChose";

class GiftEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choseEventVisible: false,
    };
  }

  showChoseEvent = () => {
    this.setState({choseEventVisible: true});
  };

  hideChoseEvent = () => {
    this.setState({choseEventVisible: false});
  };

  handleChoseEventCancel = () => {
    this.hideChoseEvent();
  };

  render = () => {
    const {gift} = this.props;

    let isTransferable =
      gift.getCreator().getId() === this.props.userID
      && gift.getPurchaseDate() === null
    ;
    if (!isTransferable) {
      return "";
    }
    return <>
      <Icon link name="calendar alternate outline" onClick={this.showChoseEvent} title="Transférer le cadeau" style={{marginLeft: "1em"}} />
      {this.state.choseEventVisible && <GiftEventsChose gift={this.props.gift} handleClose={this.handleChoseEventCancel} />}
    </>;
  };
}

export default GiftEvents;
