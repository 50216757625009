import React, {Component} from 'react';
import {Dropdown, Menu, Container} from "semantic-ui-react";
import MenuLink from "./MenuLink";
import {connect} from "react-redux";
import {withRouter} from "../withRouter";
import {selectEvent, toggleSearch} from "../../../actions";
import styled from "styled-components";

const EventDropDown = styled(Dropdown)`
    z-index: 101;
`;
const ContainerTitle = styled(Container)`
    display: block !important;
`;
const MenuItemTitle = styled(Menu.Item)`
    display: block !important;
    color: #59F !important;
    font-weight: bold !important;
`;

class HeadMenu extends Component {

  hasRole = (role) => {
    return this.props.roles && this.props.roles.includes(role);
  };

  handleSelectEvent = (event, { value }) => {
    if (this.props.events[value]) {
      return this.props.selectEvent(this.props.events[value]);
    }
    return null;
  };

  render() {
    let data = [];
    for (let event of Object.values(this.props.events)) {
      data.push({ key: event.getId(), text: event.getName(), value: event.getId() });
    }
    data.sort((a, b) => a.text > b.text ? -1 : 1);

    let adminMenu = '';

    if (this.hasRole('ROLE_ADMIN')) {
      adminMenu = <Dropdown item text='Gérer'>
        <Dropdown.Menu>
          <MenuLink to="/events" label="Événements"/>
          <MenuLink to="/members" label="Membres"/>
        </Dropdown.Menu>
      </Dropdown>;
    }

    return (
      <Menu pointing secondary fluid>
        <Menu.Menu>
          <MenuLink to="/gifts" label="Cadeaux"/>
          <MenuLink to="/balance" label="Comptes"/>
          <MenuLink to="/account" label="Mon&nbsp;compte"/>
          {adminMenu}
          <Dropdown item icon='search' text='' onClick={()=>{this.props.toggleSearch()}}>
            <Dropdown.Menu />
          </Dropdown>
        </Menu.Menu>
        <ContainerTitle textAlign="center">
          <MenuItemTitle>{this.props.title}</MenuItemTitle>
        </ContainerTitle>
        <Menu.Menu position='right'>
          {Object.keys(this.props.events).length > 0 && this.hasRole('ROLE_USER') &&
          <EventDropDown
            onChange={this.handleSelectEvent}
            options={data}
            placeholder='Événement'
            simple
            item
            value={this.props.currentEvent.getId()}
          />
          }
          {!!this.props.roles && <MenuLink to="/logout" label="Quitter"/>}
        </Menu.Menu>
      </Menu>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentEvent: state.event,
    events: state.events,
    roles: state.auth.roles,
    search: state.search,
    title: state.common.title,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectEvent: (event) => dispatch(selectEvent(event)),
    toggleSearch: () => {dispatch(toggleSearch())},
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeadMenu));
