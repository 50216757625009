import React, {Fragment} from "react";
import {Button, Grid} from "semantic-ui-react";
import Authenticate from "./Login/Authenticate";
import {connect} from "react-redux";
import {setTitle, generateNewPasswordToken, showError} from "../../actions";
import ResetPassword from "./Login/ResetPassword";
import PasswordToken from "./Login/PasswordToken";

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      toggleAuthenticateResetPassword: false
    };
  }

  componentDidMount() {
    this.props.setTitle("Gestion cadeaux");
  }

  togglePasswordToken = () => {
    this.setState({toggleAuthenticateResetPassword: true})
  }

  toggleAuthenticate = () => {
    this.setState({toggleAuthenticateResetPassword: false})
  }

  render() {
    return (
      <Fragment>
        <Grid divided='vertically'>
          <Grid.Row columns='equal'>
            <Grid.Column/>
            <Grid.Column width={8}>
              {!this.state.toggleAuthenticateResetPassword && <Authenticate location={this.props.location}/>}
              {this.state.toggleAuthenticateResetPassword && <>
                <ResetPassword handleResetDone={this.toggleAuthenticate}/>
                <Button type="submit" secondary onClick={this.toggleAuthenticate} className={"right floated"}>Annuler</Button></>}
            </Grid.Column>
            <Grid.Column/>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column/>
            <Grid.Column width={8}>
              {!this.state.toggleAuthenticateResetPassword && <PasswordToken handleTokenGenerated={this.togglePasswordToken}/>}
            </Grid.Column>
            <Grid.Column/>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form.login
  };
};

export default connect(mapStateToProps, {setTitle, generateNewPasswordToken, showError})(Login);
