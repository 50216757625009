import moment from "moment";

class Events {

  /**
   * @param {DTOBuilder} dtoBuilder
   * @param {Axios} axios
   */
  constructor(dtoBuilder, axios) {
    this.dtoBuilder = dtoBuilder;
    this.axios = axios;
  }

  /**
   * Fetch the users participating to the given event
   * @param {Event} event
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {User[]}
   */
  getUsers = (event, cbSuccess, cbError) => {
    return this.axios
      .get(`/events/${event.getId()}/users`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Creates a new event (creator will be the one from the token)
   * @param {string} name
   * @param {string|Date} date
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Event}
   */
  create = (name, date, cbSuccess, cbError) => {
    date = moment(date).format("YYYY-MM-DD");
    return this.axios
      .post('/events', {
        name,
        date,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  delete = (id, cbSuccess, cbError) => {
    return this.axios
      .delete(`/events/${id}`)
      .then(function (response) {
        cbSuccess(response.data.data);
      })
      .catch(cbError)
    ;
  };

  update = (id, name, date, cbSuccess, cbError) => {
    return this.axios
      .patch(`/events/${id}`, {
        name,
        date,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Register a user to an event
   * @param {Event} event
   * @param {User} user
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  register = (event, user, cbSuccess, cbError) => {
    return this.axios
      .post(`/events/${event.getId()}/users/${user.getId()}/registration`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };

  /**
   * Unregister a user to an event
   * @param {Event} event
   * @param {User} user
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  unregister = (event, user, cbSuccess, cbError) => {
    return this.axios
      .delete(`/events/${event.getId()}/users/${user.getId()}/registration`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };
}

export default Events;
