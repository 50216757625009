import React from "react";
import {Dimmer, Loader} from "semantic-ui-react";
import {connect} from "react-redux";

const Status = ({nbPendingRequests, message}) => (
  (nbPendingRequests > 0) &&
  <Dimmer active page>
    {/*<Loader size='massive'>{!!message ? message : 'Chargement'}</Loader>*/}
    <Loader size='massive'>{!!message ? message : 'Chargement'}</Loader>
  </Dimmer>
);

const mapStateToProps = state => {
  return {
    // loading: state.common.loading,
    message: state.common.message,
    nbPendingRequests: state.common.nbPendingRequests,
  };
};

export default connect(mapStateToProps)(Status);
