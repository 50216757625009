import Contributions from "./Resources/Contributions";
import Comments from "./Resources/Comments";
import Events from "./Resources/Events";
import Gifts from "./Resources/Gifts";
import Users from "./Resources/Users";
import DTOBuilder from "./DTOBuilder";

class Client {
  constructor(apiUrl, onStartRequest, onEndRequest, onError) {
    this.axios = require('axios').create({
      baseURL: apiUrl,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // Add interceptor at the start of each request to trigger an event that a resource is loading
    this.axios.interceptors.request.use(
      function (config) {
        onStartRequest();
        return config;
      },
      function (error) {
        onEndRequest();
        if (onError) {
          onError(error);
        }
        return Promise.reject(error);
      }
    );

    // Add interceptor at the end of each request to trigger event that the resource has been loaded
    this.axios.interceptors.response.use(
      function (config) {
        onEndRequest();
        return config;
      },
      function (error) {
        onEndRequest();
        if (onError) {
          onError(error);
        }
        return Promise.reject(error);
      }
    );

    let dtoBuilder = new DTOBuilder();
    this.commentsResource = new Comments(dtoBuilder, this.axios);
    this.eventsResource = new Events(dtoBuilder, this.axios);
    this.giftsResource = new Gifts(dtoBuilder, this.axios);
    this.usersResource = new Users(dtoBuilder, this.axios);
    this.contributionsResource = new Contributions(dtoBuilder, this.axios);
  }

  comments = () => {
    return this.commentsResource;
  };
  events = () => {
    return this.eventsResource;
  };
  gifts = () => {
    return this.giftsResource;
  };
  users = () => {
    return this.usersResource;
  };
  contributions = () => {
    return this.contributionsResource;
  };
}

export default Client;
