import React from "react";
import {Icon, Item, Label, List} from "semantic-ui-react";
import {getGift} from "../../../../actions";
import {connect} from "react-redux";
import GiftBoughtContribution from "./GiftBoughtContribution";

class GiftBought extends React.Component
{
   render() {
    const {gift, contributions} = this.props;

    let remaining = gift.getPurchasePrice();
    for (let contribution of Object.values(contributions)) {
      if (contribution.isCollected()) {
        remaining -= contribution.getAmount();
      }
    }

    let labelRemainingColor;
    labelRemainingColor = remaining > 0 ? "orange" : "green";

    return (
      <Item>
        <Item.Content style={{width: "23em"}}>
          <Item.Header as="a" onClick={() => this.props.getGift(gift.getId())}>{gift.getName()}</Item.Header>
          <Item.Meta>
            <List horizontal>
              <List.Item key="price"><Label tag color='yellow'>{gift.getPurchasePrice()} <Icon name='euro' /></Label></List.Item>
            {Object.values(gift.getRecipients()).map((recipient) =>
              <List.Item key={recipient.getId()}><Label>{recipient.getFirstname()}</Label></List.Item>
            )}
            </List>
          </Item.Meta>
          Reste : <Label color={labelRemainingColor}>{remaining} <Icon name='euro' /></Label>
        </Item.Content>
        <Item.Content style={{float: "right", width: "23em"}}>
          <List>
          {Object.values(contributions).map((contribution) => {
            // :-/ Partial objects ...
            contribution.gift = gift;
            return <GiftBoughtContribution contribution={contribution} key={contribution.getId()} />
          })}
          </List>
        </Item.Content>
      </Item>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGift: (id) => dispatch(getGift(id)),
  };
};

export default connect(null, mapDispatchToProps)(GiftBought);
