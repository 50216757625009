import React from "react";
import {Navigate, Route, Routes} from 'react-router-dom'

import { Account, Balance, Events, Gifts, Login, Logout, Members } from './Pages'
import PrivateRoute from "./PrivateRoute";

const Router = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/gifts"/>} />
    <Route path="/events" element={<PrivateRoute role={"ROLE_MANAGER"} component={Events} />}/>
    <Route path="/members" element={<PrivateRoute role={"ROLE_ADMIN"} component={Members} />}/>
    <Route path="/gifts" element={<PrivateRoute role={"ROLE_MEMBER"} component={Gifts} />}/>
    <Route path="/balance" element={<PrivateRoute role={"ROLE_MEMBER"} component={Balance} />}/>
    <Route path="/account" element={<PrivateRoute role={"ROLE_MEMBER"} component={Account} />}/>
    <Route path="/login" element={<Login />}/>
    <Route path="/logout" element={<Logout />}/>
  </Routes>
);

export default Router;
