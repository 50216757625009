import * as React from "react";
import {Component} from "react";
import {Field, reduxForm} from 'redux-form'
import {Form} from "semantic-ui-react";
import {DateTimePicker} from 'react-widgets'

export class Update extends Component {

  render() {
    const {handleSubmit, onSubmit} = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Input
            id="name"
            name="name"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Nom"
            label="Nom de l'événement"
            control={Field}
          />
          <Form.Field className="form-control" required={true}>
            <label>Date de l'événement</label>
            <Field
              id="date"
              name="date"
              required={true}
              type="input"
              placeholder="Date"
              label="Date de l'événement"
              showTime={false}
              component={this.renderDateTimePicker}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }

  renderDateTimePicker = ({input: {onChange, value}, showTime}) =>
    <DateTimePicker
      onChange={onChange}
      format="DD MMM YYYY"
      includeTime={showTime}
      value={!value ? null : new Date(value)}
    />;
}

export default reduxForm({
  form: 'update-event'
})(Update);
