class User
{
  constructor(id, selfLink, firstname, lastname, email, deletable) {
    this.id = id;
    this.selfLink = selfLink;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.deletable = deletable;
  }

  getId = () => {
    return this.id;
  };
  getSelfLink = () => {
    return this.selfLink;
  };
  getFirstname = () => {
    return this.firstname;
  };
  getLastname = () => {
    return this.lastname;
  };
  getEmail = () => {
    return this.email;
  };
  isDeletable = () => {
    return this.deletable;
  };
}

export default User;
