import * as React from "react";
import {Component} from "react";
import {Field, reduxForm} from 'redux-form'
import {Form} from "semantic-ui-react";

export class Update extends Component {

  render() {
    const {handleSubmit, onSubmit} = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Input
            id="firstname"
            name="firstname"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Prénom"
            label="Prénom"
            control={Field}
          />
          <Form.Input
            id="lastname"
            name="lastname"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Nom"
            label="Nom"
            control={Field}
          />
          <Form.Input
            id="email"
            name="email"
            required={true}
            className="form-control"
            component="input"
            type="email"
            placeholder="Email"
            label="Email"
            control={Field}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'update-member'
})(Update);
