
class Comments {

  /**
   * @param {DTOBuilder} dtoBuilder
   * @param {Axios} axios
   */
  constructor(dtoBuilder, axios) {
    this.dtoBuilder = dtoBuilder;
    this.axios = axios;
  }

  /**
   * Create a comment
   * @param {Gift} gift
   * @param {string} text
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Comment}
   */
  create = (gift, text, cbSuccess, cbError) => {
    return this.axios
      .post('/comments', {
        gift_id: gift.getId(),
        text,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch a comment
   * @param {integer} id
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Comment[]}
   */
  get = (id, cbSuccess, cbError) => {
    return this.axios
      .get(`/comments/${id}`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Delete a comment
   * @param {integer} id
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise|Q.Promise<any>|Promise<void>|Promise<any>}
   */
  delete = (id, cbSuccess, cbError) => {
    return this.axios
      .delete(`/comments/${id}`)
      .then(function (response) {
        cbSuccess(response.data.data);
      })
      .catch(cbError)
    ;
  };

  /**
   * Update a comment
   * @param {integer}id
   * @param {Gift} gift
   * @param {string} text
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {ThenPromise<any> | Q.Promise<any> | Promise<void>}
   */
  update = (id, gift, text, cbSuccess, cbError) => {
    return this.axios
      .post(`/comments/${id}`, {
        gift_id: gift.getId(),
        text,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };
}

export default Comments;
