import Event from "./Event";

class EventFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'event') {
      throw new Error("Given raw data not fit for event DTO");
    }
    return new Event(
      data.id,
      data.links.self,
      data.attributes.name,
      data.attributes.deletable,
      data.attributes.occurs
    );
  }
}

export default EventFactory;
