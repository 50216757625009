import React from "react";

import {connect} from "react-redux";
import Summary from "./Summary";


class SummaryEvent extends React.Component {
  render() {
    const {event, gifts, auth} = this.props
    if (event && gifts && auth.userID) {
      return <Summary event={event} gifts={gifts} userID={auth.userID} />;
    }
    return '';
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    event: state.event,
    gifts: state.gifts,
  }
};

export default connect(mapStateToProps)(SummaryEvent);
