
class Gifts {

  /**
   * @param {DTOBuilder} dtoBuilder
   * @param {Axios} axios
   */
  constructor(dtoBuilder, axios) {
    this.dtoBuilder = dtoBuilder;
    this.axios = axios;
  }

  /**
   * Create a gift
   * @param {string} name
   * @param {string} description
   * @param {Event} event
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  create = (name, description, event, cbSuccess, cbError) => {
    return this.axios
      .post('/gifts', {
        name,
        description,
        event_id: event.getId()
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Update a gift
   * @param {gift} gift
   * @param {?string} name
   * @param {?string} description
   * @param {?string} observedPrice
   * @param {?boolean} requireContribution
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  update = (gift, name = undefined, description = undefined, observedPrice = undefined, requireContribution = undefined, cbSuccess, cbError) => {
    let payload = {};
    if (name !== undefined) {
      payload.name = name;
    }
    if (description !== undefined) {
      payload.description = description;
    }
    if (observedPrice !== undefined) {
      payload.observedPrice = observedPrice;
    }
    if (requireContribution !== undefined) {
      payload.require_contribution = requireContribution;
    }
    return this.axios
      .patch(`/gifts/${gift.getId()}?fields=recipients,contributors,comments`, payload)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch the gifts of an event
   * @param {Event} event
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Gift[]}
   */
  getByEvent = (event, cbSuccess, cbError) => {
    return this.axios
      .get(`/events/${event.getId()}/gifts?fields=recipients,contributors,comments`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch the events for this gift (and current user)
   * @param {Gift} gift
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Event[]}
   */
  getEvents = (gift, cbSuccess, cbError) => {
    return this.axios
      .get(`/gifts/${gift.getId()}/events`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch the contributions of this gift
   * @param {Gift} gift
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Contribution[]}
   */
  getContributions = (gift, cbSuccess, cbError) => {
    return this.axios
      .get(`/gifts/${gift.getId()}/contributions`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Creates a bound between the gift and the event
   * @param {Gift} gift
   * @param {Event} event
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  bindToEvent = (gift, event, cbSuccess, cbError) => {
    return this.axios
      .post(`/bindings/events/${event.getId()}/gifts/${gift.getId()}`)
      .then(function (response) {
        cbSuccess();
      })
      .catch(cbError)
    ;
  };

  /**
   * Fetch a gift
   * @param {integer} id
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Gift[]}
   */
  getGift = (id, cbSuccess, cbError) => {
    return this.axios
      .get(`/gifts/${id}?fields=recipients,contributors,comments`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Track a gift
   * @param {integer} id
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  track = (id, cbSuccess, cbError) => {
    return this.axios
      .post(`/gifts/${id}/tracking`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };

  /**
   * Untrack a gift
   * @param {integer} id
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  untrack = (id, cbSuccess, cbError) => {
    return this.axios
      .delete(`/gifts/${id}/tracking`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };

  /**
   * Record purchase of a gift
   * @param {Gift} gift
   * @param {float} amount
   * @param {Event} event
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  purchase = (gift, amount, event, cbSuccess, cbError) => {
    return this.axios
      .patch(`/gifts/${gift.getId()}/purchase?fields=recipients,contributors,comments`, {
        price: amount,
        event_purchase: event.getId()
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Cancel purchase of a gift
   * @param {Gift} gift
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  purchaseCancel = (gift, cbSuccess, cbError) => {
    return this.axios
      .delete(`/gifts/${gift.getId()}/purchase?fields=recipients,contributors,comments`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Create a recipient to a gift
   * @param {Gift} gift
   * @param {User} user
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise<T>}
   */
  createRecipient = (gift, user, cbSuccess, cbError) => {
    return this.axios
      .post('/recipient', {
        gift_id: gift.getId(),
        user_id: user.getId(),
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Delete a recipient to a gift
   * @param {Gift} gift
   * @param {User} user
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise|Q.Promise<any>|Promise<void>|Promise<any>}
   */
  deleteRecipient = (gift, user, cbSuccess, cbError) => {
    return this.axios
      .delete(`/recipient/${gift.getId()}/${user.getId()}`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };

  /**
   * Delete a gift
   * @param {Gift} gift
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise|Q.Promise<any>|Promise<void>|Promise<any>}
   */
  delete = (gift, cbSuccess, cbError) => {
    return this.axios
      .delete(`/gifts/${gift.getId()}`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };

  /**
   * Search gifts
   * @param {string} keyword
   * @param {User} recipientID
   * @param {User} contributorID
   * @param {boolean} bought
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Gift[]}
   */
  search = (keyword=undefined, recipientID=undefined, contributorID=undefined, bought=undefined, cbSuccess, cbError) => {
    return this.axios
      .get(`/gifts?fields=recipients,contributors,comments${keyword?`&keyword=${keyword}`:''}${recipientID?`&recipientId=${recipientID}`:''}${contributorID?`&contributorId=${contributorID}`:''}${bought?`&bought=${bought}`:''}`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Update the requireContribution field of a gift
   * @param {Gift} gift
   * @param {boolean} requireContribution
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  setRequireContribution = (gift, requireContribution, cbSuccess, cbError) => {
    return this.axios
      .patch(`/gifts/${gift.getId()}/require-contribution?fields=recipients,contributors,comments`, {
        require_contribution: requireContribution,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };
}

export default Gifts;
