import React, {Component} from 'react'
import {Button, Icon, Label, List, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {createRecipient} from "../../../../actions";

class RecipientAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseRecipientVisible: false,
      candidate: null,
    };
  }

  selectCandidate = (user) => {
    this.setState({candidate: user});
  };

  showChooseRecipient = () => {
    this.setState({chooseRecipientVisible: true});
  };

  hideChooseRecipient = () => {
    this.setState({chooseRecipientVisible: false});
    this.setState({candidate: null});
  };

  handleAddRecipient = () => {
    this.props.addRecipient(this.props.gift, this.state.candidate, function() {
      this.hideChooseRecipient();
    }.bind(this), function() {
      this.hideChooseRecipient();
    }.bind(this));
  };

  render = () => {
    const {gift, users} = this.props;
    let header = null;
    let content = null;
    let actions = null;

    if (gift.getCreator().getId() !== this.props.auth.userID) {
      return null;
    }

    let recipients = [...Object.values(users)].sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : 0)

    if (this.state.chooseRecipientVisible) {
      header = 'Ajouter un nouveau destinataire à ce cadeau';
      if (this.state.candidate) {
        content = <>Ajouter <Label>{this.state.candidate.getFirstname()}</Label> comme destinataire du cadeau <i>{gift.getName()}</i> ?</>;
      } else {
        content = <List horizontal>
          {Object.entries(recipients).map(function([key, user]) {
            for (let recipient of Object.values(gift.getRecipients())) {
              if (user.getId() === recipient.getId()) {
                return;
              }
            }
            return <List.Item key={"recipient_candidate_"+user.getId()}>
              <Label as="a" onClick={() => this.selectCandidate(user)}>{user.getFirstname()}</Label>
            </List.Item>;
          }.bind(this))}
        </List>;
      }
      actions = <Modal.Actions>
        <Button color="red" onClick={this.hideChooseRecipient}>Annuler</Button>
        {!! this.state.candidate && <Button color="green" onClick={this.handleAddRecipient}>Ajouter</Button>}
      </Modal.Actions>;
    }

    return <Modal
      trigger={<Icon link name="add user" color="blue" onClick={this.showChooseRecipient} title="Ajouter un destinataire" />}
      open={this.state.chooseRecipientVisible}
      closeIcon={true}
      onClose={this.hideChooseRecipient}
      size="small"
    ><Modal.Header>{header}</Modal.Header><Modal.Content>{content}</Modal.Content>{actions}</Modal>;
  };
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift: state.gift,
    users: state.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
     addRecipient: (gift, user, callback, callbackError) => dispatch(createRecipient(gift, user, callback, callbackError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientAdd);
