import {EVENT_SELECTED, EVENTS_LOADED} from "../actions/constants";
import {selectEvent, getUsers, loadGifts} from "../actions";
import moment from "moment";

export function selectCurrentEvent({dispatch}) {
  return function (next) {
    return function (action) {
      if (action.type === EVENTS_LOADED) {
        let now = moment();
        let nextEvent = null;
        let eventDate = null;
        for (let event of Object.values(action.payload)) {
          if (nextEvent === null) {
            nextEvent = event;
          } else {
            eventDate = moment.parseZone(event.getDate());
            if (eventDate.isAfter(moment.parseZone(nextEvent.getDate()))) {
              nextEvent = event;
              if (eventDate.isAfter(now)) {
                break;
              }
            }
          }
        }
console.log("Next event found", nextEvent);
        if (nextEvent) {
          dispatch(selectEvent(nextEvent));
        }
      }
      return next(action);
    }
  }
}

export function loadEventUsers({dispatch}) {
  return function (next) {
    return function (action) {
      if (action.type === EVENT_SELECTED) {
        dispatch(getUsers(action.event));
      }
      return next(action);
    }
  }
}

export function loadSelectEventGifts({dispatch}) {
  return function (next) {
    return function (action) {
      if (action.type === EVENT_SELECTED) {
        dispatch(loadGifts(action.event));
      }
      return next(action);
    }
  }
}
