import React, {Component} from "react";
import {Table, Header} from 'semantic-ui-react'
import { connect } from 'react-redux';
import ListRow from "./ListRow";
import _ from "lodash";

class List extends Component
{
  state = {
    column: 'firstname',
    direction: 'ascending',
    showConfirmDelete: [],
    showModalEdit: [],
    selectedMember: undefined
  };

  handleSelect = (member) => {
    this.setState({
      selectedMember: member
    });
    this.props.handleSelect(member.obj);
  };

  handleSort = clickedColumn => () => {
    const {column, direction} = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      });
      return
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  };

  renderRow(member) {
    return <ListRow
      key={member.id}
      member={member}
      active={this.state.selectedMember && member.id === this.state.selectedMember.id}
      handleUpdate={this.props.handleUpdate}
      handleDelete={this.props.handleDelete}
      handleSelect={this.handleSelect}
    />;
  }

  render() {
    const { members } = this.props;
    if (!members) {
      return '';
    }

    const { column, direction } = this.state;
    let rows = [];

    let data = [];
    for (let member of Object.values(members)) {
      data.push({
        id: member.getId(),
        firstname: member.getFirstname(),
        lastname: member.getLastname(),
        email: member.getEmail(),
        deletable: member.isDeletable(),
        obj: member,
      })
    }
    data = _.sortBy(data, [column]);
    data = direction === 'ascending' ? data : data.reverse();

    rows.push(data
      .map((member) => {
        return this.renderRow(member);
      })
    );

    return (<>
      <Header as='h3'>Liste des membres</Header>
      <Table sortable celled fixed striped selectable>
        <Table.Header>
          <Table.Row key="member-list">
            <Table.HeaderCell width={3} sorted={column === 'firstname' ? direction : null} onClick={this.handleSort('firstname')}>Prénom</Table.HeaderCell>
            <Table.HeaderCell width={3} sorted={column === 'lastname' ? direction : null} onClick={this.handleSort('lastname')}>Nom</Table.HeaderCell>
            <Table.HeaderCell width={3} sorted={column === 'email' ? direction : null} onClick={this.handleSort('email')}>Email</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' width={3}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    </>);
  }
}

export default connect()(List)
