import React from "react";

import {setTitle, showError} from "../../actions";
import {connect} from "react-redux";
import {Grid, Header} from "semantic-ui-react";
import UpdatePassword from "./Account/UpdatePassword";

class Account extends React.Component {
  state = {
  };

  componentDidMount() {
    this.props.setTitle("Mon compte");
  }

  render() {
    return (<>
      <Header>Mon compte</Header>
      <Grid divided='vertically'>
        <Grid.Row columns='equal'>
          <Grid.Column/>
          <Grid.Column width={8}>
            <UpdatePassword />
          </Grid.Column>
          <Grid.Column/>
        </Grid.Row>
      </Grid>
    </>);
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    showError: (error, details) => dispatch(showError(error, details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
