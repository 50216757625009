import React, {Component} from "react";
import {Card, Icon} from 'semantic-ui-react';
import {connect} from "react-redux";
import {css} from 'styled-components'
import styled from "styled-components";
import {getGift} from "../../../../actions";
import moment from "moment";


const bought = css`
  background-color: rgb(255, 200, 185) !important;
`;

const pending = css`
  opacity: 0.33;
`;

const CardGift = styled(Card)`
  background-color: rgb(179, 202, 255) !important;
  border-width: 1px !important;
  width: auto !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  padding: .2em;
  box-shadow: none !important;
`;

class Gift extends Component {

  render() {
    const {gift, contributor} = this.props;

    let unreadCount = 0;
    let lastCheck = gift.getDateLastCheck() ? moment(gift.getDateLastCheck()) : null;
    if (gift.getComments()) for (let comment of Object.values(gift.getComments())) {
      if (!lastCheck || moment(comment.getDate()).isAfter(lastCheck)) {
        unreadCount++;
      }
    }

    return this._renderAsCard(gift, contributor, unreadCount);
  }

  handleClick(id) {
    this.props.getGift(id);
  }

  _renderAsCard(gift, contributor, unreadCount) {
    let pendingContribution = false;
    if (contributor && contributor.getId() !== this.props.auth.userID) {
      for (let contribution of Object.values(gift.getContributions())) {
        if (contributor.getId() === contribution.getUser().getId() && !contribution.isAccepted()) {
          pendingContribution = true;
        }
      }
    }

    let CardTag = styled(CardGift)`
      ${gift.getPurchaseDate() ? bought : ''}
      ${pendingContribution ? pending : ''}
    `;

    return (
      <CardTag link onClick={() => this.handleClick(gift.getId())}>
        {this._renderCardContent(gift, contributor, unreadCount)}
      </CardTag>
    );
  }

  _renderCardContent(gift, contributor, unreadCount) {
    let stickerStarred = null;
    let stickerComment = null;
    let stickerContribution = null;
    let stickerCreator = null;

    if (gift.isTracked()) {
      stickerStarred = <Icon name={"eye"} color={"black"} className="ui floating top left" title={"Vous suivez ce cadeau"} />
    }

    if (gift.getComments()) {
      let commentNb = Object.entries(gift.getComments()).length;
      if (commentNb > 0) {
        if (unreadCount > 0) {
          stickerComment = <div className="ui label circular floating top right red">{unreadCount}</div>
        } else {
          stickerComment = <div className="ui label circular floating top right blue tiny">{commentNb}</div>
        }
      }
    }
    if (contributor && this.props.auth.userID && gift.getContributions()) {
      for (let contribution of Object.values(gift.getContributions())) {
        if (contributor.getId() === this.props.auth.userID && contribution.getUser().getId() === this.props.auth.userID) {
          if (contribution.isAccepted() === null) {
            stickerContribution = <Icon name={"question"} color={"orange"} className="ui floating bottom left" size="large" title={"Participation en attente"} />
          } else if (contribution.isAccepted()) {
            stickerContribution = <Icon name={"check circle"} color={"green"} className="ui floating bottom left" title={"Vous avez accepté de participer"} />
          } else {
            stickerContribution = <div className="ui label circular floating bottom left yellow mini">✘</div>
            stickerContribution = <Icon name={"close"} color={"red"} className="ui floating bottom left" title={"Participation refusée"} />
          }
        }
      }
    }
    if (gift.getCreator().getId() === this.props.auth.userID) {
      stickerCreator = <Icon name={"pencil"} color={"blue"} className="" title={"Vous êtes le créateur de ce cadeau"} />
    }

    return (
      <>
        <Card.Header textAlign='center'>{gift.getName()}{!!stickerCreator && stickerCreator}</Card.Header>
        {!!stickerStarred && stickerStarred}
        {!!stickerComment && stickerComment}
        {!!stickerContribution && stickerContribution}
        <span/>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getGift: (id) => dispatch(getGift(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gift);
