class Recipient
{
  constructor(id, gift, user) {
    this.id = id;
    this.gift = gift;
    this.user = user;
  }

  getId = () => {
    return this.id;
  };
  // getSelfLink = () => {
  //   return this.selfLink;
  // };
  /** @return {Gift} */
  getGift = () => {
    return this.gift;
  };
  /** @return {User} */
  getUser = () => {
    return this.user;
  };
}

export default Recipient;
