import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import {selectCurrentEvent, loadEventUsers, loadSelectEventGifts} from "./middleware";
import thunk from "redux-thunk";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer
  , storeEnhancers(
    applyMiddleware(selectCurrentEvent, loadEventUsers, loadSelectEventGifts, thunk)
  )
  // , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
