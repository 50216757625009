import {Container} from "./Container";
import Config from "./Config";
import moment from "moment";
import MomentLocalizer from 'react-widgets-moment';
import store from "./store";

moment.updateLocale('fr', require('moment/locale/fr'));
new MomentLocalizer(moment);

export default new Container(new Config(), store);
