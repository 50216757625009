import React, {Component} from 'react'
import {Form, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {giftCreate} from "../../../../actions";
import {Field, reduxForm} from "redux-form";

class CreateModal extends Component {

  submitCreate = (values) => {
    const {reset, handleClose} = this.props;
    this.props.giftCreate(
      values.name,
      values.description,
      this.props.event,
      function () {
        handleClose();
        reset();
      },
      function () {
        handleClose();
        reset();
      }
    );
  };

  render() {
    const {submitting} = this.props;

    return (
      <Modal as={Form}
             open={this.props.open} closeIcon={true}
             onSubmit={this.props.handleSubmit(this.submitCreate)}
             onClose={this.props.handleClose}
             size="small">
        <Modal.Header>Ajouter un cadeau</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <Form.Field
              id="name"
              name="name"
              required={true}
              className="form-control"
              component="input"
              type="text"
              placeholder="Nom"
              label="Nom du cadeau"
              control={Field}
            />
            <Form.Field
              id="description"
              name="description"
              required={false}
              className="form-control"
              component="input"
              type="text"
              placeholder="Description"
              label="Description"
              control={Field}
            />
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Form.Group inline  floated='right'>
            <Form.Button color="red" onClick={this.props.handleClose}>Annuler</Form.Button>
            <Form.Button color="green" type='submit' disabled={submitting}>Ajouter</Form.Button>
          </Form.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    giftCreate: (name, description, event, cs, ce) => dispatch(giftCreate(name, description, event, cs, ce)),
  };
};

export default reduxForm({
  form: "create-gift"
})(connect(mapStateToProps, mapDispatchToProps)(CreateModal));

