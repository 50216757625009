import React, {Component} from 'react';
import {Header} from "semantic-ui-react";
import HeadMenu from "./Head/HeadMenu";
import {connect} from "react-redux";

class Head extends Component {
  hasRole = (role) => {
    return this.props.roles && null !== this.props.roles[role];
  };
  render() {
    return (
      <>
        {this.hasRole('ROLE_USER') && <HeadMenu/>}
        {!this.hasRole('ROLE_USER') && <Header as="h1" dividing>{this.props.title}</Header>}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    roles: state.auth.roles,
    title: state.common.title,
  };
};

export default connect(mapStateToProps)(Head);
