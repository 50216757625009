import React from "react";
import {Navigate} from "react-router-dom";
import {connect} from "react-redux";
import {authenticate} from "../../../actions";
import {Field, reduxForm} from "redux-form";
import {Button, Form} from "semantic-ui-react";

class Authenticate extends React.Component
{
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirectToReferrer: false
    };
  }

  handleSubmit = (values) => {
    this.props.authenticate(values.email, values.password)
      .then(function () {
        this.setState({redirectToReferrer: true});
      }.bind(this))
    ;
  };

  render() {
    const {from} = this.props?.location?.state || {from: {pathname: "/"}};
    const {redirectToReferrer} = this.state;

    if (redirectToReferrer) {
      return <Navigate to={from}/>;
    }

    return (
      <>
        <p>Saisissez votre courriel et votre mot-de-passe pour vous connecter.</p>
        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Form.Group widths='equal'>
            <Field
              component={Form.Input}
              label="Courriel"
              name="email"
              placeholder="Courriel"
            />
            <Field
              component={Form.Input}
              type="password"
              label="Mot-de-passe"
              name="password"
              placeholder="Mot-de-passe"
            />
          </Form.Group>
          <Button type="submit" primary className={"left floated"}>Se connecter</Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.form.login
  };
};

export default reduxForm({form: 'login'})(connect(mapStateToProps, {authenticate})(Authenticate));
