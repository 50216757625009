import React, {Component} from 'react'
import {Button, Confirm, Form, Item, ItemHeader, Label} from "semantic-ui-react";
import {Field, reduxForm, initialize} from "redux-form";

import NumberInput from 'semantic-ui-react-numberinput';
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import moment from "moment";

class ActionPurchase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showEmptyRange: false,
      amount: this.props.gift.getPurchasePrice() ? this.props.gift.getPurchasePrice() : null,
      confirmDeleteVisible: false,
    };
  }

  handleAmountChange = (amount) => {
    this.setState({
      amount: amount
    }, () => {
      // Callback the reduxForm initial change callback
      this.props.change("purchase", amount);
    });
  };

  resetAmount = (event) => {
    this.setState({
      amount: this.props.gift.getPurchasePrice() ? this.props.gift.getPurchasePrice() : null,
    }, () => {
      // Callback the reduxForm initial reset callback
      this.props.reset();
    });
    event.preventDefault();
    event.stopPropagation();
  };

  showConfirmDelete = (event) => {
    this.setState({confirmDeleteVisible: true});
    event.preventDefault();
    event.stopPropagation();
  };

  hideConfirmDelete = () => {
    this.setState({confirmDeleteVisible: false});
  };

  handleConfirmDeleteCancel = () => {
    this.hideConfirmDelete();
  };
  handleConfirmDeleteOk = () => {
    this.hideConfirmDelete();
    // does not work ...
    this.props.handleSubmit(this.props.onPurchaseDelete);
    // direct call to delete function
    this.props.onPurchaseDelete(function() {
      this.setState({amount: null}, () => {
      this.props.change("purchase", null);
      });
    }.bind(this));
  };

  handlePurchase = () => {
    this.props.onPurchase(Number(this.state.amount), function () {
      this.props.resetFormWith(this.state);
      this.setState({
        showEmptyRange: false,
      });
    }.bind(this));
  };

  showEmptyRange = () => {
    this.setState({
      showEmptyRange: true,
    });
  };

  hideEmptyRange = () => {
    this.setState({
      amount: null,
      showEmptyRange: false,
    }, () => {
      this.props.change("purchase", null);
    });
  };

  renderPurchaseButton() {
    return (
      <Form.Group inline>
        <label style={{margin: 0}}>Vous avez acheté ce cadeau ?</label>
        <Form.Button onClick={this.showEmptyRange} color="green" title="Acheter">Signaler l'achat</Form.Button>
      </Form.Group>
    );
  };

  renderUpdatePurchase = () => {
    const {pristine, submitting} = this.props;

    return (
      <Form.Group inline>
        <label style={{margin: 0}}>Prix d'achat</label>
        <Field
          name="price"
          className="ui input numberInput"
          props={{
            value: String(this.state.amount ? this.state.amount : ''),
            allowEmptyValue: true,
            onChange: this.handleAmountChange,
            placeholder: "Prix",
            valueType: "decimal",
            precision: 2,
          }}
          component={NumberInput}
        />
        <Form>
          <Button.Group floated='right'>
            <Form.Button onClick={this.handlePurchase}
                         type="button" size="mini" disabled={pristine || submitting} color="green"
                         title="Modifier le prix d'achat"
            >✔</Form.Button>
            <Button.Or text='ou'/>
            <Form.Button size="small" color="orange" disabled={pristine || submitting} title="Annuler le changement" onClick={this.resetAmount}>✘</Form.Button>
            <Button.Or text='ou'/>
            <Form.Button onClick={(event) => this.showConfirmDelete(event)} type="submit" size="small"
                         disabled={submitting} color="red"
                         title="Supprimer l'achat"
            >Ø</Form.Button>
            <Confirm
              open={!!this.state.confirmDeleteVisible}
              header={"Suppression d'un achat"}
              content={"Voulez-vous supprimer l'achat que vous avez saisi pour ce cadeau ?"}
              cancelButton="Non"
              confirmButton="Oui"
              onCancel={this.handleConfirmDeleteCancel}
              onConfirm={this.handleConfirmDeleteOk}
              dimmer="blurring"
            />
          </Button.Group>
        </Form>
      </Form.Group>
    );
  };

  renderCreatePurchase = () => {
    const {pristine, submitting} = this.props;

    return (
      <Form.Group inline>
        <label style={{margin: 0}}>Prix d'achat</label>
        <Field
          name="price"
          className="ui input numberInput"
          props={{
            value: String(this.state.amount ? this.state.amount : ''),
            allowEmptyValue: true,
            onChange: this.handleAmountChange,
            placeholder: "Prix",
            valueType: "decimal",
            precision: 2,
          }}
          component={NumberInput}
        />
        <Form>
          <Button.Group floated='right'>
            <Form.Button onClick={this.handlePurchase}
                         type="button" size="mini" disabled={pristine || submitting} color="green"
                         title="Enregistrer ce prix d'achat"
            >✔</Form.Button>
            <Button.Or text='ou'/>
            <Form.Button size="small" color="orange" disabled={false} title="Annuler" onClick={this.hideEmptyRange}>✘</Form.Button>
          </Button.Group>
        </Form>
      </Form.Group>
    );
  };

  renderPurchaseInfo = () => {
    let gift = this.props.gift;

    // Prevent the recipient from seeing the amount (even if he created the gift)
    for (let recipient of Object.values(gift.getRecipients())) {
      if (recipient.getId() === this.props.userID) {
        return <></>
      }
    }

    return (
      <>
        {gift.getPurchaseDate() ?
          <Item>
            <ItemHeader><Icon name="shop" title="Acheté"/></ItemHeader>
            <Item.Content verticalAlign='middle'>Acheté le {moment.parseZone(gift.getPurchaseDate()).format('DD/MM/YYYY à HH[h]mm')} par <Label>{gift.getBuyer().getFirstname()}</Label></Item.Content>
          </Item>
          : ''}
        {gift.getPurchasePrice() ?
          <Item>
            <ItemHeader><Icon name="euro" title="Prix payé"/></ItemHeader>
            <Item.Content verticalAlign='middle'>Montant {gift.getPurchasePrice()}</Item.Content>
          </Item>
          : ''}
      </>
    );
  };

  render() {
    let gift = this.props.gift;
    let userID = this.props.userID;

    if (gift.getBuyer() || this.state.showEmptyRange) {
      if (this.state.showEmptyRange) {
        return this.renderCreatePurchase();
      }
      if (gift.getBuyer().getId() === userID) {
        return (<>
          {this.renderPurchaseInfo()}
          {this.renderUpdatePurchase()}
        </>);
      } else {
        return this.renderPurchaseInfo();
      }
    } else {
      return this.renderPurchaseButton();
    }
  }
}


const mapDispatchToProps = dispatch => {
  return {
    resetFormWith: (state) => dispatch(initialize('updatePurchase', state)),
  };
};

export default reduxForm({form: 'updatePurchase'})(connect(null, mapDispatchToProps)(ActionPurchase));
