import React from "react";

import List from "./Gifts/List";
import {setTitle} from "../../actions";
import {connect} from "react-redux";

class Gifts extends React.Component
{
  state = {
    currentEventId: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.event && props.event.getId() !== state.currentEventId) {
      return {
        currentEventId: props.event.getId(),
      };
    }
    return null;
  }
  componentDidMount() {
    this.props.setTitle(this.props.event?.getName());
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.setTitle(this.props.event?.getName());
  }

  render() {
    return <List />
  }
}

const mapStateToProps = state => {
  return {
    event: state.event,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gifts);
