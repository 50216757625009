import React, {Component} from "react";
import {Table, Header} from 'semantic-ui-react'
import moment from "moment";
import { connect } from 'react-redux';
import ListRow from "./ListRow";
import _ from "lodash";

class List extends Component
{
  state = {
    column: 'date',
    direction: 'descending',
    showConfirmDelete: [],
    showModalEdit: [],
    selectedEvent: undefined
  };

  handleSelect = (event) => {
    this.setState({
      selectedEvent: event
    });
    this.props.handleSelect(event.obj);
  };

  handleSort = clickedColumn => () => {
    const {column, direction} = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      });
      return
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  };

  renderRow(event) {
    return <ListRow
      key={event.id}
      event={event}
      active={this.state.selectedEvent && event.id === this.state.selectedEvent.id}
      handleUpdate={this.props.handleUpdate}
      handleDelete={this.props.handleDelete}
      handleSelect={this.handleSelect}
    />;
  }

  render() {
    const { events } = this.props;
    if (!events) {
      return '';
    }

    const { column, direction } = this.state;
    let now = moment();
    let rows = [];

    let data = [];
    for (let event of Object.values(events)) {
      data.push({
        id: event.getId(),
        name: event.getName(),
        date: event.getDate(),
        deletable: event.isDeletable(),
        obj: event,
      })
    }
    data = _.sortBy(data, [column]);
    data = direction === 'ascending' ? data : data.reverse();

    if (column === 'date') {
      rows.push(data
        .filter((event) => {
          return ((direction === 'descending' && moment.parseZone(event.date).isAfter(now)) || (direction === 'ascending' && moment.parseZone(event.date).isBefore(now)));
        })
        .map((event) => {
          return this.renderRow(event);
        })
      );
      rows.push(
        <Table.Row key="now-separator">
          <Table.Cell colSpan={3} warning={true} textAlign='center'>Aujourd'hui</Table.Cell>
        </Table.Row>
      );
      rows.push(data
        .filter((event) => {
          return ((direction === 'descending' && moment.parseZone(event.date).isBefore(now)) || (direction === 'ascending' && moment.parseZone(event.date).isAfter(now)));
        })
        .map((event) => {
          return this.renderRow(event);
        })
      );
    } else {
      rows.push(data
        .map((event) => {
          return this.renderRow(event);
        })
      );
    }

    return (<>
      <Header as='h3'>Evénements que vous avez créés</Header>
      <Table sortable celled fixed striped selectable>
        <Table.Header>
          <Table.Row key="event-list">
            <Table.HeaderCell width={6}
              sorted={column === 'name' ? direction : null}
              onClick={this.handleSort('name')}
            >Événement</Table.HeaderCell>
            <Table.HeaderCell width={4}
              sorted={column === 'date' ? direction : null}
              onClick={this.handleSort('date')}
            >Date</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' width={3}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    </>);
  }
}

export default connect()(List)
