class Users {

  /**
   * @param {DTOBuilder} dtoBuilder
   * @param {Axios} axios
   */
  constructor(dtoBuilder, axios) {
    this.dtoBuilder = dtoBuilder;
    this.axios = axios;
    this.axiosInterceptorAddToken = null;
  }

  userLogin (username, password) {
    return this.axios
      .post('/login', {
        username: username,
        password: password
      });
  };

  getPublicKey() {
    return this.axios.get('/.pub');
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  login = (username, password, cbSuccess, cbError) => {
    return Promise.all([this.userLogin(username, password), this.getPublicKey()])
      .then(function ([userLoginResponse, getPublicKeyResponse]) {
        this.axiosInterceptorAddToken = this.axios.interceptors.request.use(
          function (config) {
            // Add bearer token at each request
            config.headers = Object.assign({}, config.headers, {
              'Authorization': 'Bearer ' + userLoginResponse.data.token,
            });
            return config;
          // }, function (error) {
          //   cbError(error);
          //   return Promise.reject(error);
          },
          cbError
        );
        cbSuccess(userLoginResponse.data.token, getPublicKeyResponse.data);
      }.bind(this))
      .catch(cbError)
    ;
  };

  logout = () => {
    this.axios.interceptors.request.eject(this.axiosInterceptorAddToken);
    // TODO Call API to invalidate token
  };

  /**
   * Fetch all users
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {User[]}
   */
  getUsers = (cbSuccess, cbError) => {
    return this.axios
      .get(`/users`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Creates a new member (creator will be the one from the token)
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} email
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {User}
   */
  create = (firstname, lastname, email, cbSuccess, cbError) => {
    return this.axios
      .post('/users', {
        firstname,
        lastname,
        email,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Deletes a user/member
   * @param id
   * @param {function} cbSuccess
   * @param {function} cbError
   */
  delete = (id, cbSuccess, cbError) => {
    return this.axios
      .delete(`/users/${id}`)
      .then(function (response) {
        cbSuccess(response.data.data);
      })
      .catch(cbError)
    ;
  };

  /**
   * Updates a user/member
   * @param id
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} email
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {User}
   */
  update = (id, firstname, lastname, email, cbSuccess, cbError) => {
    return this.axios
      .patch(`/users/${id}`, {
        firstname,
        lastname,
        email,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch the events to which the given user participate (or the logged user if none provided)
   * @param {function} cbSuccess
   * @param {function} cbError
   * @param {User} user
   * @return {Event[]}
   */
  getEvents = (cbSuccess, cbError, user = null) => {
    return this.axios
      .get(`/users/${user ? user.getId() : 'current'}/events`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Fetch a user's contribution
   * @param {function} cbSuccess
   * @param {function} cbError
   * @param {Event} event
   * @param {User} user
   * @return {Contribution[]}
   */
  getContributions = (cbSuccess, cbError, event, user = null) => {
    return this.axios
      .get(`/users/${user ? user.getId() : 'current'}/events/${event.getId()}/contributions`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
      ;
  };

  /**
   * Fetch the events created by the given user (or the logged user if none provided)
   * @param {function} cbSuccess
   * @param {function} cbError
   * @param {User} user
   * @return {Event[]}
   */
  getEventsCreated = (cbSuccess, cbError, user = null) => {
    return this.axios
      .get(`/users/${user ? user.getId() : 'current'}/created-events`)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Generate a new password token on the server side (the token will be sent to the user by email)
   * @param {string} email
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Event[]}
   */
  generateNewPasswordToken = (email, cbSuccess, cbError) => {
    return this.axios
      .post(`/users/email/${email}/password/token`)
      .then(function (response) {
        cbSuccess();
      })
      .catch(cbError)
    ;
  };

  /**
   * Resets a user's password (the new password will be sent to the user by email)
   * @param {string} email
   * @param {string} token
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Event[]}
   */
  resetPassword = (email, token, cbSuccess, cbError) => {
    return this.axios
      .patch(`/users/email/${email}/password`, {token})
      .then(function (response) {
        cbSuccess();
      })
      .catch(cbError)
    ;
  };

  /**
   * Update the currently connected user's password
   * @param {string} password
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Event[]}
   */
  updatePassword = (password, cbSuccess, cbError) => {
    return this.axios
      .patch(`/users/current/password`, {password})
      .then(function (response) {
        cbSuccess();
      })
      .catch(cbError)
    ;
  };
}

export default Users;
