class Role
{
  constructor(id) {
    this.id = id;
  }

  getId = () => {
    return this.id;
  };
}

export default Role;
