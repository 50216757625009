import React from 'react'
import {Form} from 'semantic-ui-react'
import {connect} from "react-redux";
import container from "../../../bootstrap";
import {resetError, showError} from "../../../actions";
import {Field, reduxForm} from "redux-form";
import FormSelect from "../FormSelect";

class SearchForm extends React.Component {

  state = {
    users: {},
  }

  constructor(props) {
    super(props);

    // Fetch all users at startup
    container.getApiClient().users().getUsers(
      function (users) {
        this.props.resetError();
        this.setState({
          users: [...Object.values(users)].sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : -1),
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les utilisateurs", error);
      }.bind(this)
    );
  }

  render() {
    let users = [];
    for (let user of Object.values(this.state.users)) {
      users.push({key: user.getId(), value: user.getId(), text: user.getFirstname()})
    }

    const {handleSubmit, onSubmit} = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Input
            className="form-control"
            control={Field}
            component="input"
            id="keyword"
            label="Mot-clé"
            name="keyword"
            placeholder="3 caractères minimum"
            required={true}
            type="text"
          />
          <Form.Select
            control={Field}
            component={FormSelect}
            id="contributor"
            label='Participant'
            name="contributor"
            options={users}
            placeholder='Participant'
            required={false}
          />
          <Form.Select
            control={Field}
            component={FormSelect}
            id="recipient"
            label='Destinataire'
            name="recipient"
            options={users}
            placeholder='Destinataire'
            required={false}
          />
          <Form.Select
            control={Field}
            component={FormSelect}
            id="bought"
            label='Acheté ?'
            name="bought"
            options={[{key: "1", value: "1", text: "Oui"}, {key: "2", value: "0", text: "Non"}]}
            placeholder='Oui/Non'
          />
        </Form.Group>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetError,
    showError,
  };
};

export default reduxForm({
  form: 'search-gifts'
})(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
