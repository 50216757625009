import Gift from "./Gift";

class GiftFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'gift') {
      throw new Error("Given raw data not fit for gift DTO");
    }

    return new Gift(
      data.id,
      data.links.self,
      data.attributes.name,
      data.attributes.description,
      data.attributes.observed_price,
      data.attributes.price,
      data.attributes.created_at,
      data.attributes.bought_at,
      data.relationships && data.relationships.event_purchase ? this.DTOBuilder.getRelationship(data.relationships.event_purchase) : null,
      data.relationships && data.relationships.buyer ? this.DTOBuilder.getRelationship(data.relationships.buyer) : null,
      data.relationships && data.relationships.creator ? this.DTOBuilder.getRelationship(data.relationships.creator) : null,
      data.relationships && data.relationships.recipients ? this.DTOBuilder.getRelationship(data.relationships.recipients) : {},
      data.relationships && data.relationships.contributors ? this.DTOBuilder.getRelationship(data.relationships.contributors) : {},
      data.relationships && data.relationships.contributions ? this.DTOBuilder.getRelationship(data.relationships.contributions) : {},
      data.relationships && data.relationships.comments ? this.DTOBuilder.getRelationship(data.relationships.comments) : {},
      data.attributes.last_check,
      data.attributes.tracked,
      data.attributes.deletable,
      data.attributes.require_contribution,
    );
  }
}

export default GiftFactory;
