import User from "./User";

class UserFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'user') {
      throw new Error("Given raw data not fit for user DTO");
    }
    return new User(
      data.id,
      data.links.self,
      data.attributes.firstname,
      data.attributes.lastname,
      data.attributes.email,
      data.attributes.deletable,
    );
  }
}

export default UserFactory;
