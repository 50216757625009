import {EVENT_SELECTED} from "../actions/constants";

const initialState = null;

export default function event(state = initialState, action) {
  if (action.type === EVENT_SELECTED) {
    return action.event;
  }
  return state;
}
