import {
  CONTRIBUTION_CREATED,
  CONTRIBUTION_UPDATED,
  CONTRIBUTION_DELETED,
  CONTRIBUTION_CANDIDATE_CREATED, CONTRIBUTION_CANDIDATE_DELETED
} from "./constants";
import container from "../bootstrap";
import {showError} from "./error";

/**
 * @param {Gift} gift
 * @param {number|null} amount
 * @param {boolean|null} isAccepted
 * @param {boolean|null} isCollected
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function createContribution(gift, amount = null, isAccepted = null, isCollected = null, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().contributions().create(
      gift,
      amount,
      isAccepted,
      isCollected,
      function(contribution) {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_CREATED,
          payload: contribution,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de créer la participation", error));
      }
    );
  }
}

/**
 * @param {Contribution} contribution
 * @param {number|null} amount
 * @param {boolean|null} isAccepted
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function contributeContribution(contribution, amount, isAccepted, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().contributions().contribute(
      contribution,
      amount,
      isAccepted,
      function(contribution) {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_UPDATED,
          payload: contribution,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de mettre à jour le montant de la participation", error));
      }
    );
  }
}

/**
 * @param {Contribution} contribution
 * @param {number|null} amount
 * @param {boolean|null} isCollected
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function collectContribution(contribution, amount, isCollected, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().contributions().collect(
      contribution,
      amount,
      isCollected,
      function(contribution) {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_UPDATED,
          payload: contribution,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible d'accepter/refuser la participation", error));
      }
    );
  }
}

/**
 * @param {Contribution} contribution
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function deleteContribution(contribution, callback = null, callbackError = null) {
  return function (dispatch) {
    let  contributionId = contribution.getId();
    let  giftId = contribution.getGift().getId();
    let  userId = contribution.getUser().getId();
    return container.getApiClient().contributions().delete(
      contribution.getGift(),
      function () {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_DELETED,
          payload: {
            id: contributionId,
            giftId,
            userId,
          },
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de supprimer la participation", error));
      }
    );
  }
}

/**
 * @param {Gift} gift
 * @param {User} candidate
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function createContributionCandidate(gift, candidate, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().contributions().createCandidate(
      gift,
      candidate,
      function(contribution) {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_CANDIDATE_CREATED,
          payload: contribution,
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de créer la candidature de participation", error));
      }
    );
  }
}

/**
 * @param {Contribution} contribution
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): Promise|Q.Promise<any>|Promise<void>|Promise<any>}
 */
export function deleteContributionCandidate(contribution, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().contributions().deleteCandidate(
      contribution.getGift(),
      contribution.getUser(),
      function() {
        if (callback) {
          callback(contribution);
        }
        dispatch({
          type: CONTRIBUTION_CANDIDATE_DELETED,
          payload: {
            id: contribution.getId(),
            giftId: contribution.getGift().getId(),
            userId: contribution.getUser().getId(),
          },
        });
      },
      function(error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de supprimer la candidature de participation", error));
      }
    );
  }
}
