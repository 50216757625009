import {AUTH_LOGIN, AUTH_LOGOUT} from "../actions/constants";

const initialState = {
  user: null,
  userID: null,
  // user: "dummy",
  roles: null,
  // roles: ['ROLE_ADMIN'],
};

export default function auth(state = initialState, action) {
  if (action.type === AUTH_LOGIN) {
    return Object.assign({}, state, {
      user: action.payload.user,
      userID: action.payload.userID,
      roles: action.payload.roles,
    });
  }
  if (action.type === AUTH_LOGOUT) {
    return Object.assign({}, state, {
      user: null,
      userID: null,
      roles: null,
    });
  }
  return state;
}
