import React, {Component} from "react";
import {Modal, Confirm, Button, Icon, Table} from 'semantic-ui-react'
import Update from "./Update";
import { submit } from 'redux-form';
import { connect } from 'react-redux';

class ListRow extends Component
{

  state = {
    showConfirmDelete: [],
    showModalEdit: [],
  };

  handleConfirmDelete = (id) => {
    this.setState({showConfirmDelete: Object.assign([], this.state.showConfirmDelete, [id]) });
  };
  handleConfirmDeleteOk = (id) => {
    this.props.handleDelete(id);
    this.hideConfirmDelete(id);
  };
  handleConfirmDeleteCancel = (id) => {
    this.hideConfirmDelete(id);
  };
  hideConfirmDelete = (id) => {
    this.setState({ showConfirmDelete: this.state.showConfirmDelete.filter(function (v, i) {return i === id;}) });
  };

  handleModalEdit = (id) => {
    this.setState({showModalEdit: Object.assign([], this.state.showModalEdit, [id]) });
  };
  handleModalEditOk = (id, data) => {
    this.props.handleUpdate(id, data.firstname, data.lastname, data.email);
    this.hideModalEdit(id);
  };
  handleModalEditCancel = (id) => {
    this.hideModalEdit(id);
  };
  hideModalEdit = (id) => {
    this.setState({ showModalEdit: this.state.showModalEdit.filter(function (v, i) {return i === id;}) });
  };

  handleSelect = (e, member) => {
    this.props.handleSelect(member);
    e.preventDefault();
    e.stopPropagation();
  };

  dispatchToForm = () => {
    this.props.dispatchToForm();
  };

  render() {
    const { member } = this.props;

    return (
      <Table.Row key={member.id} selectable="true" active={this.props.active}>
        <Table.Cell selectable>
          <a href="/#" onClick={(e) => this.handleSelect(e, member)}>{member.firstname}</a>
        </Table.Cell>
        <Table.Cell selectable>
          <a href="/#" onClick={(e) => this.handleSelect(e, member)}>{member.lastname}</a>
        </Table.Cell>
        <Table.Cell selectable>
          <a href="/#" onClick={(e) => this.handleSelect(e, member)}>{member.email}</a>
        </Table.Cell>
        <Table.Cell>
          <button onClick={() => this.handleModalEdit(member.id)} className={"ui right floated icon button blue"}><Icon
            name={"edit"}/></button>
          <Modal
            size='small'
            open={!!this.state.showModalEdit.find((element) => {
              return element === member.id;
            })}
            onClose={() => this.handleModalEditCancel(member.id)}
          >
            <Modal.Header>Modifier {member.firstname}</Modal.Header>
            <Modal.Content>
              <Update initialValues={member} onSubmit={(data) => this.handleModalEditOk(member.id, data)}/>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => this.hideModalEdit(member.id)}>Annuler</Button>
              <Button positive onClick={this.dispatchToForm} icon='checkmark' labelPosition='right' content='Mettre à jour'/>
            </Modal.Actions>
          </Modal>
          {member.deletable && (<>
            <button onClick={() => this.handleConfirmDelete(member.id)}
                    className={"ui right floated icon button orange"}>
              <Icon name={"trash alternate"}/></button>
            <Confirm
              open={!!this.state.showConfirmDelete.find((element) => {
                return element === member.id;
              })}
              header={"Suppression d'un membre"}
              content={`Voulez-vous supprimer ${member.firstname} ?`}
              cancelButton="Non"
              confirmButton="Oui"
              onCancel={() => this.handleConfirmDeleteCancel(member.id)}
              onConfirm={() => this.handleConfirmDeleteOk(member.id)}
              dimmer="blurring"
            />
          </>)}
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchToForm: () => dispatch(submit('update-member')),
  };
};

export default connect(null, mapDispatchToProps)(ListRow);
