import React from "react";
import {connect} from "react-redux";
import {generateNewPasswordToken} from "../../../actions";
import {Field, reduxForm} from "redux-form";
import {Button, Form} from "semantic-ui-react";

class PasswordToken extends React.Component
{
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirectToReferrer: false
    };
  }

  handleSubmit = (values) => {
    this.props.generateNewPasswordToken(
      encodeURI(values.email),
      function () {
        this.props.handleTokenGenerated();
      }.bind(this)
    );
  };

  render() {
    return (
      <>
        <p>Si vous n'avez plus votre mot-de-passe, utilisez le formulaire suivant.
          <br/>Vous pourrez ainsi générer un code qui vous sera envoyé par courriel (pour vérifier qu'il s'agit bien de vous).
          Vous pourrez alors utiliser ce code pour générer aléatoirement un nouveau mot-de-passe.</p>
        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Form.Group widths='equal'>
            <Field
              component={Form.Input}
              label="Courriel"
              name="email"
              placeholder="Courriel"
              required={true}
            />
          </Form.Group>
          <Button type="submit" secondary>Obtenir un code de renouvellement</Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // form: state.form.passwordToken
  };
};

export default reduxForm({form: 'passwordToken'})(connect(mapStateToProps, {generateNewPasswordToken})(PasswordToken));
