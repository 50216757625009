class Contributions {

  /**
   * @param {DTOBuilder} dtoBuilder
   * @param {Axios} axios
   */
  constructor(dtoBuilder, axios) {
    this.dtoBuilder = dtoBuilder;
    this.axios = axios;
  }

  /**
   * Create a contribution (logged user)
   * @param {Gift} gift
   * @param {number|null} amount
   * @param {boolean|null} isAccepted
   * @param {boolean|null} isCollected
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise<T>}
   */
  create = (gift, amount = null, isAccepted = null, isCollected = null, cbSuccess, cbError) => {
    return this.axios
      .post('/contributions', {
        gift_id: gift.getId(),
        amount,
        accepted: isAccepted,
        collected: isCollected,
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Update a contribution (logged user)
   * @param {Contribution} contribution
   * @param {number|null} amount
   * @param {boolean|null} isAccepted
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise<T>}
   */
  contribute = (contribution, amount = undefined, isAccepted = undefined, cbSuccess, cbError) => {
    let payload = {};
    if (amount !== undefined) {
      payload.amount = amount;
    }
    if (isAccepted !== undefined) {
      payload.accepted = isAccepted;
    }
    return this.axios
      .patch(`/contributions/${contribution.getGift().getId()}/contribute`, payload)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Update a contribution collect value for a given contribution
   * Access restricted to admin or gift buyer
   * @param {Contribution} contribution
   * @param {number|null} amount
   * @param {boolean|null} isCollected
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise<T>}
   */
  collect = (contribution, amount = undefined, isCollected = undefined, cbSuccess, cbError) => {
    let payload = {};
    if (amount !== undefined) {
      payload.amount = amount;
    }
    if (isCollected !== undefined) {
      payload.collected = isCollected;
    }
    return this.axios
      .patch(`/contributions/${contribution.getGift().getId()}-${contribution.getUser().getId()}/collect`, payload)
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Delete a contribution (logged user)
   * @param {Gift} gift
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise|Q.Promise<any>|Promise<void>|Promise<any>}
   */
  delete = (gift, cbSuccess, cbError) => {
    return this.axios
      .delete(`/contributions/${gift.getId()}`)
      .then(function (response) {
        cbSuccess(response.data.data);
      })
      .catch(cbError)
    ;
  };

  /**
   * Create a contribution candidature
   * @param {Gift} gift
   * @param {User} candidate
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise<T>}
   */
  createCandidate = (gift, candidate, cbSuccess, cbError) => {
    return this.axios
      .post('/contributions/candidate', {
        gift_id: gift.getId(),
        user_id: candidate.getId(),
      })
      .then(function (response) {
        cbSuccess(this.dtoBuilder.buildDTOsFromRawData(response.data));
      }.bind(this))
      .catch(cbError)
    ;
  };

  /**
   * Delete a contribution candidature
   * @param {Gift} gift
   * @param {User} candidate
   * @param {function} cbSuccess
   * @param {function} cbError
   * @return {Promise|Q.Promise<any>|Promise<void>|Promise<any>}
   */
  deleteCandidate = (gift, candidate, cbSuccess, cbError) => {
    return this.axios
      .delete(`/contributions/candidate/${gift.getId()}/${candidate.getId()}`)
      .then(cbSuccess)
      .catch(cbError)
    ;
  };
}

export default Contributions;
