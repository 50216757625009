import {EVENTS_LOADED} from "../actions/constants";

const initialState = [];

export default function events(state = initialState, action) {
  if (action.type === EVENTS_LOADED) {
    return action.payload;
  }
  return state;
}
