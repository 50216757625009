import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {Form, Header} from "semantic-ui-react";

export class Create extends Component {

  handleSubmit = (values) => {
    this.props.handleCreate(values.firstname, values.lastname, values.email);
  };

  render() {
    const {pristine, reset, submitting} = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}>
        <Header as='h3'>Créer un nouveau membre</Header>
        <Form.Group>
          <Form.Field
            id="firstname"
            name="firstname"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Prénom"
            label="Prénom"
            control={Field}
          />
          <Form.Field
            id="lastname"
            name="lastname"
            required={true}
            className="form-control"
            component="input"
            type="text"
            placeholder="Nom de famille"
            label="Nom"
            control={Field}
          />
          <Form.Field
            id="email"
            name="email"
            required={true}
            className="form-control"
            component="input"
            type="email"
            placeholder="Email"
            label="Email"
            control={Field}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Button primary disabled={pristine || submitting}>
            Créer
          </Form.Button>
          <Form.Button type="button" disabled={pristine || submitting} onClick={reset}>
            Annuler
          </Form.Button>
        </Form.Group>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'create-member'
})(Create);
