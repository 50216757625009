import React, {Component} from 'react'
import {Button, Icon, Label, List, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {createContributionCandidate} from "../../../../actions";

class ContributorAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choseContributorVisible: false,
      candidate: null,
    };
  }

  selectCandidate = (user) => {
    this.setState({candidate: user});
  };

  showChooseContributor = () => {
    this.setState({choseContributorVisible: true});
  };

  hideChooseContributor = () => {
    this.setState({choseContributorVisible: false});
    this.setState({candidate: null});
  };

  handleAddContributor = () => {
    this.props.addContributionCandidate(this.props.gift, this.state.candidate, function() {
      this.hideChooseContributor();
    }.bind(this), function() {
      this.hideChooseContributor();
    }.bind(this));
  };

  render = () => {
    const {gift, users} = this.props;
    let header = null;
    let content = null;
    let actions = null;

    let contributors = [...Object.values(users)].sort((a, b) => a.getFirstname() > b.getFirstname() ? 1 : 0)

    if (this.state.choseContributorVisible) {
      header = 'Proposez un nouveau participant pour ce cadeau';
      if (this.state.candidate) {
        content = <>Proposer à <Label>{this.state.candidate.getFirstname()}</Label> de participer au cadeau <i>{gift.getName()}</i> ?</>;
      } else {
        content = <List horizontal>
          {Object.entries(contributors).map(function([key, user]) {
            for (let contributor of Object.values(gift.getContributors())) {
              if (user.getId() === contributor.getId()) {
                return;
              }
            }
            return <List.Item key={"contributor_candidate_"+user.getId()}>
              <Label as="a" onClick={() => this.selectCandidate(user)}>{user.getFirstname()}</Label>
            </List.Item>;
          }.bind(this))}
        </List>;
      }
      actions = <Modal.Actions>
        <Button color="red" onClick={this.hideChooseContributor}>Annuler</Button>
        {!! this.state.candidate && <Button color="green" onClick={this.handleAddContributor}>Proposer</Button>}
      </Modal.Actions>;
    }

    return <Modal
      trigger={<Icon link name="add user" color="blue" onClick={this.showChooseContributor} title="Ajouter un participant" />}
      open={this.state.choseContributorVisible}
      closeIcon={true}
      onClose={this.hideChooseContributor}
      size="small"
    ><Modal.Header>{header}</Modal.Header><Modal.Content>{content}</Modal.Content>{actions}</Modal>;
  };
}

const mapStateToProps = state => {
  return {
    gift: state.gift,
    users: state.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContributionCandidate: (gift, candidate, callback, callbackError) => dispatch(createContributionCandidate(gift, candidate, callback, callbackError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributorAdd);
