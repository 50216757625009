import {PAGE_TITLE, API_REQUEST_START, API_REQUEST_END} from "./constants";

export function setTitle(title = "Gestion cadeaux") {
  return {
    type: PAGE_TITLE,
    title
  }
}

export function apiRequestStarted(requestId = null) {
  return {
    type: API_REQUEST_START,
    requestId
  }
}

export function apiRequestEnded(requestId = null) {
  return {
    type: API_REQUEST_END,
    requestId
  }
}
