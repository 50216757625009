import React, {Component} from 'react'
import {Confirm, Label, List} from 'semantic-ui-react'
import {connect} from "react-redux";
import styled from "styled-components";
import RemoveIcon from "./RemoveIcon";
import {deleteContributionCandidate} from "../../../../actions";

const ContributorLabel = Label;

const ContributorLabelPending = styled(Label)`
  opacity: 0.33;
`;

class Contributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteVisible: false,
    };
  }

  showConfirmDelete = () => {
    this.setState({confirmDeleteVisible: true});
  };

  hideConfirmDelete = () => {
    this.setState({confirmDeleteVisible: false});
  };

  handleConfirmDeleteCancel = () => {
    this.hideConfirmDelete();
  };

  handleConfirmDeleteOk = () => {
    const {contribution} = this.props;
    this.props.deleteContributionCandidate(contribution, function() {
      this.hideConfirmDelete();
    }.bind(this), function() {
      this.hideConfirmDelete();
    }.bind(this));
  };

  render = () => {
    const {contribution} = this.props;

    let Tag = contribution.isAccepted() ? ContributorLabel : ContributorLabelPending;
    let deleteIcon = '';

    if (!contribution.isAccepted()) {
      deleteIcon = <RemoveIcon link onClick={this.showConfirmDelete} name="remove user" color="red" />
    }

    let content = <>{deleteIcon}<Tag>{contribution.getUser().getFirstname()}</Tag></>;
    let confirmContent = <div className='content'>Voulez-vous supprimer la participation en attente de <ContributorLabel>{contribution.getUser().getFirstname()}</ContributorLabel> ?</div>;

    return <>
      <Confirm
        open={!!this.state.confirmDeleteVisible}
        header={"Suppression d'une participation"}
        content={confirmContent}
        cancelButton="Non"
        confirmButton="Oui"
        onCancel={this.handleConfirmDeleteCancel}
        onConfirm={this.handleConfirmDeleteOk}
        dimmer="blurring"
      />
      <List.Item content={content} />
    </>;
  };

}

const mapDispatchToProps = dispatch => {
  return {
    deleteContributionCandidate: (contribution, callback, callbackError) => dispatch(deleteContributionCandidate(contribution, callback, callbackError)),
  };
};

export default connect(null, mapDispatchToProps)(Contributor);
