import {ERROR_SHOW, ERROR_RESET} from "../actions/constants";

const initialState = {
  message: null,
  exception: null,
};

export default function error(state = initialState, action) {
  if (action.type === ERROR_SHOW) {
    return Object.assign({}, state, {
      message: action.message,
      exception: action.exception,
    });
  }
  if (action.type === ERROR_RESET) {
    return Object.assign({}, state, {
      message: null,
      exception: null,
    });
  }
  return state;
}
