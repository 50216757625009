import React, {Component} from 'react'
import {Button, Form} from "semantic-ui-react";

class Pending extends Component {

  handleParticipationAccept = () => {
    this.props.onContributionUpdate(undefined, true);
  };

  handleParticipationRefuse = () => {
    this.props.onContributionUpdate(undefined, false);
  };

  render() {
    if (!this.props.contribution || this.props.contribution.isAccepted() !== null) {
      return;
    }
    let text = "Participation en attente";
    if (this.props.contribution.amount) {
      text += ` (${this.props.contribution.amount} €)`
    }
    return (
      <Form.Group inline>
        <label style={{margin: 0}}>{text}</label>
        <Button.Group floated='right'>
          <Form.Button onClick={this.handleParticipationAccept}
                       color="green" title="Accepter la participation">✔</Form.Button>
          <Button.Or text='ou'/>
          <Form.Button onClick={this.handleParticipationRefuse}
                       color="red" title="Refuser la participation">✘</Form.Button>
        </Button.Group>
      </Form.Group>
    );
  };

}

export default Pending;
