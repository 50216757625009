import React, {Component} from 'react'
import {Form} from "semantic-ui-react";

class Contribute extends Component {

  render() {
    return (
      <Form.Group inline>
        <label style={{margin: 0}}>Vous ne participez pas à ce cadeau</label>
        <Form.Button onClick={this.props.onContributionCreate}
                     color="green" title="Participer">Participer</Form.Button>
      </Form.Group>
    );
  };
}

export default Contribute;
