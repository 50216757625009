import React from 'react'
import {Icon, Dropdown, Form} from 'semantic-ui-react'

const iconStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto',
  right: '2em',
  lineHeight: 1,
  zIndex: 1,
}

const containerStyle = {
  position: 'relative',
  display: 'inline-block',
}

class FormSelect extends React.Component {
  state = {
    value: this.props.value
  }

  handleClear = e => {
    this.setState({ value: '' })
    this.props.input.onChange()
  }

  handleChange = (none, data) => {
    this.setState({value: data.value})
    this.props.input.onChange(data.value)
  }

  render() {
    const { value } = this.state

    return (
      <Form.Field style={containerStyle}>
        {this.props.label && <label>{this.props.label}</label>}
        <Icon link name="close" style={iconStyle} onClick={this.handleClear} />
        <Dropdown
          selection
          value={value}
          onChange={this.handleChange}
          {...this.props}
        />
      </Form.Field>
    )
  }
}

export default FormSelect;
