import {AUTH_LOGIN, AUTH_LOGOUT} from "./constants";
import container from "../bootstrap";

import {showError, resetError} from './error';
import {getEvents} from "./events";
import * as jwt from "jsonwebtoken";

export function login(username, password) {
  return function (dispatch) {
    return container.getApiClient().users().login(
      username,
      password,
      function (token, publicKey) {
        try {
          let decoded = jwt.verify(token, publicKey, {
            algorithms: ['RS512']
          });
          dispatch({
            type: AUTH_LOGIN,
            payload: {
              user: decoded.username,
              userID: String(decoded.oid),
              roles: decoded.roles,
            }
          });
        } catch (e) {
          dispatch(showError("Impossible de décoder le jeton", e));
        }
      },
      function (error) {
        let httpCode = error?.response?.data?.code;
        if (httpCode && httpCode === 401) {
          dispatch(showError("Courriel ou mot-de-passe invalide", error));
        } else {
          dispatch(showError("Erreur de connexion", error));
        }
      }
    );
  }
}

export function logout() {
  container.getApiClient().users().logout();
  return {
    type: AUTH_LOGOUT
  }
}

export function authenticate(username, password) {
  return function (dispatch, getState) {
    return dispatch(login(username, password))
      .then(() => {
        if (getState().auth.user !== null) {
          return dispatch(getEvents()).then(() => {
            dispatch(resetError());
          });
        }
      })
    ;
  }
}
