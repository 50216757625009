import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import auth from "./reducers/auth";
import event from "./reducers/event";
import events from "./reducers/events";
import gift from "./reducers/gift";
import gifts from "./reducers/gifts";
import error from "./reducers/error";
import common from "./reducers/common";
import search from "./reducers/search";
import users from "./reducers/users";

export default combineReducers({
  form: formReducer,
  auth,
  common,
  error,
  event,
  events,
  gift,
  gifts,
  search,
  users,
});
