import CommentFactory from "./DTOs/CommentFactory";
import ContributionFactory from "./DTOs/ContributionFactory";
import EventFactory from "./DTOs/EventFactory";
import GiftFactory from "./DTOs/GiftFactory";
import RecipientFactory from "./DTOs/RecipientFactory";
import RoleFactory from "./DTOs/RoleFactory";
import UserFactory from "./DTOs/UserFactory";

class DTOBuilder
{
  constructor() {
    this.factoryComment = new CommentFactory(this);
    this.factoryContribution = new ContributionFactory(this);
    this.factoryEvent = new EventFactory(this);
    this.factoryGift = new GiftFactory(this);
    this.factoryRecipient = new RecipientFactory(this);
    this.factoryRole = new RoleFactory(this);
    this.factoryUser = new UserFactory(this);

    this.relatedObjects = new Map();
  }

  buildDTOsFromRawData = (rawData) => {
    // this._extractObjects(rawData.data, this.relatedObjects);
    if (rawData.included) {
      this._mapObjects(rawData.included, this.relatedObjects);
    }
    return this._extractObjects(rawData.data, this.relatedObjects);
  };

  _mapObjects = (data, relatedObjects) => {
    if (typeof data[Symbol.iterator] === 'function') {
      data.map(objectData => {
        return relatedObjects.set(objectData.type+"-"+objectData.id, this._buildObject(objectData));
      });
    } else {
      relatedObjects.set(data.type+"-"+data.id, this._buildObject(data));
    }
  };

  _extractObjects = (data, relatedObjects) => {
    if (typeof data[Symbol.iterator] === 'function') {
      let objects = {};
      data.map(objectData => {
        let o = this._buildObject(objectData);
        objects[o.getId()] = o;
        return objects;
      });
      return objects;
    } else {
      return this._buildObject(data);
    }
  };

  _buildObject = (data) => {
    if (data.type === undefined) {
      throw new Error("Raw data is missing the 'type' attribute");
    }
    switch (data.type) {
      case 'comment':
        return this.factoryComment.build(data);
      case 'contribution':
        return this.factoryContribution.build(data);
      case 'event':
        return this.factoryEvent.build(data);
      case 'gift':
        return this.factoryGift.build(data);
      case 'recipient':
        return this.factoryRecipient.build(data);
      case 'role':
        return this.factoryRole.build(data);
      case 'user':
        return this.factoryUser.build(data);
      default:
        throw new Error(`Objects of type '${data.type}' are not handled`);
    }
  };

  getRelationship = (relationshipData) => {
    if (typeof relationshipData.data[Symbol.iterator] === 'function') {
      let objects = {};
      relationshipData.data.map(objectData => {
        let o = this.relatedObjects.get(objectData.type+"-"+objectData.id);
        if (o) {
          objects[o.getId()] = o;
        }
        return objects;
      });
      return objects;
    } else {
      return this.relatedObjects.get(relationshipData.data.type+"-"+relationshipData.data.id);
    }
  };
}

export default DTOBuilder;
