import {PAGE_TITLE, API_REQUEST_START, API_REQUEST_END} from "../actions/constants";

const initialState = {
  title: "Gestion cadeaux",
  loading: false,
  nbPendingRequests: 0,
};

export default function common(state = initialState, action) {
  if (action.type === PAGE_TITLE) {
    return Object.assign({}, state, {
      title: action.title,
    });
  }
  if (action.type === API_REQUEST_START) {
    return Object.assign({}, state, {
      nbPendingRequests: state.nbPendingRequests +1,
    });
  }
  if (action.type === API_REQUEST_END) {
    return Object.assign({}, state, {
      nbPendingRequests: state.nbPendingRequests -1,
    });
  }
  return state;
}
