import React, {Component} from 'react'
import {Confirm, Icon, Label} from 'semantic-ui-react'
import {connect} from "react-redux";
import {giftDelete} from "../../../../actions";

class GiftDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteVisible: false,
    };
  }

  showConfirmDelete = () => {
    this.setState({confirmDeleteVisible: true});
  };

  hideConfirmDelete = () => {
    this.setState({confirmDeleteVisible: false});
  };

  handleConfirmDeleteCancel = () => {
    this.hideConfirmDelete();
  };

  handleConfirmDeleteOk = () => {
    const {gift} = this.props;
    this.props.deleteGift(gift, function() {
      this.hideConfirmDelete();
    }.bind(this), function() {
      this.hideConfirmDelete();
    }.bind(this));
  };

  render = () => {
    const {gift} = this.props;

    let title = gift.isDeletable() ? "Supprimer ce cadeau" : "Supprimer (Impossible - Il ne doit y avoir aucun destinataire)";
    return <>
      <Confirm
        open={!!this.state.confirmDeleteVisible}
        header={"Suppression cadeau"}
        content={<div className='content'>Voulez-vous vraiment supprimer le cadeau <Label>{gift.getName()}</Label> ?</div>}
        cancelButton="Non"
        confirmButton="Oui"
        onCancel={this.handleConfirmDeleteCancel}
        onConfirm={this.handleConfirmDeleteOk}
        dimmer="blurring"
      />
      <Icon name="trash" color="red" title={title} link disabled={!gift.isDeletable()} onClick={this.showConfirmDelete} />
    </>;
  };
}

const mapDispatchToProps = dispatch => {
  return {
    deleteGift: (gift, callback, callbackError) => dispatch(giftDelete(gift, callback, callbackError)),
  };
};

export default connect(null, mapDispatchToProps)(GiftDelete);
