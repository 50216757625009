import React, {Component} from 'react'
import {Button, Label, List, Message, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import container from "../../../../bootstrap";
import {bindToEvent} from "../../../../actions";

class GiftEventsChose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {},
      candidate: null,
    };

    container.getApiClient().gifts().getEvents(
      this.props.gift,
      function (events) {
        // let data = {};
        // for (let event of Object.values(events)) {
        //   data[event.id] = event;
        // }
        this.setState({
          // events: data,
          events: events,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les événements pour ce cadeau", error);
      }.bind(this)
    );
  }

  selectCandidate = (event) => {
    this.setState({candidate: event});
  };

  handleChoseEventOk = () => {
    const {gift} = this.props;
    this.props.bindToEvent(gift, this.state.candidate, function() {
      this.props.handleClose();
    }.bind(this), function() {
      this.props.handleClose();
    }.bind(this));
  };

  render = () => {
    const {gift, events} = this.props;

    let header = 'Associer le cadeau à un événement ultérieur';
    let content;
    let actions;

    let eventsCandidates = [...Object.values(events)]
      .filter((event) => event.getDate() > this.props.event.getDate() ? 1 : 0)
      .sort((a, b) => a.getDate() > b.getDate() ? 1 : 0)
    ;

    if (this.state.candidate) {
      content = <>Associer le cadeau <i>{gift.getName()}</i> à l'événement <Label>{this.state.candidate.getName()}</Label> ?</>;
    } else {
      if (eventsCandidates.length === 0) {
        content = <Message color={"orange"}>Aucun événement eligible n'a été trouvé pour y associer ce cadeau.</Message>;
      } else {
        content = <>
          <Message color={"blue"}>Choisissez l'événement auquel vous voulez associer le cadeau <i>{gift.getName()}</i> :</Message>
          <List horizontal>
            {Object.entries(eventsCandidates).map(function([key, event]) {
              for (let candidate of Object.values(this.state.events)) {
                if (event.getId() === candidate.getId()) {
                  return;
                }
              }
              return <List.Item key={"event_candidate_"+event.getId()}>
                <Label as="a" onClick={() => this.selectCandidate(event)}>{event.getName()}</Label>
              </List.Item>;
            }.bind(this))}
          </List>
        </>;
      }
    }

    actions = <Modal.Actions>
      <Button color="red" onClick={this.props.handleClose}>Annuler</Button>
      {!! this.state.candidate && <Button color="green" onClick={this.handleChoseEventOk}>Associer</Button>}
    </Modal.Actions>;

    return <Modal
        open={true}
        closeIcon={true}
        onClose={this.props.handleClose}
        size="small"
      ><Modal.Header>{header}</Modal.Header><Modal.Content>{content}</Modal.Content>{actions}</Modal>;
  };
}

const mapStateToProps = state => {
  return {
    event: state.event,
    events: state.events,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bindToEvent: (gift, event, callback, callbackError) => dispatch(bindToEvent(gift, event, callback, callbackError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftEventsChose);
