import {
  GIFT_LOADED,
  GIFT_CLEAR,
  GIFTS_LOADED,
  GIFTS_CLEAR,
  GIFT_TRACKED,
  GIFT_UN_TRACKED,
  GIFT_PURCHASE,
  GIFT_PURCHASE_CANCEL,
  GIFT_CREATED,
  GIFT_UPDATED, GIFT_DELETED, GIFT_EVENT_BOUND
} from "./constants";
import container from "../bootstrap";
import {showError} from "./error";

export function clearGift() {
  return {type: GIFT_CLEAR}
}

export function getGift(id) {
  return function (dispatch) {
    return container.getApiClient().gifts().getGift(
      id,
      function (gift) {
        dispatch({
          type: GIFT_LOADED,
          payload: gift,
        });
      },
      function (error) {
        dispatch(showError("Impossible de charger ce cadeau", error));
      }
    );
  }
}

export function loadGifts(event) {
  return function (dispatch) {
    return container.getApiClient().gifts().getByEvent(
      event,
      function (gifts) {
        dispatch({
          type: GIFTS_LOADED,
          payload: gifts,
        });
      },
      function (error) {
        dispatch(showError("Impossible de charger les cadeaux", error));
      }
    )
  }
}

export function clearGifts() {
  return {type: GIFTS_CLEAR}
}

export function trackGift(id) {
  return function (dispatch) {
    return container.getApiClient().gifts().track(
      id,
      function () {
        dispatch({
          type: GIFT_TRACKED,
          payload: id,
        });
      },
      function (error) {
        dispatch(showError("Erreur lors de la demande de suivi du cadeau", error));
      }
    );
  }
}

export function unTrackGift(id) {
  return function (dispatch) {
    return container.getApiClient().gifts().untrack(
      id,
      function () {
        dispatch({
          type: GIFT_UN_TRACKED,
          payload: id,
        });
      },
      function (error) {
        dispatch(showError("Erreur lors de la demande de supression de suivi du cadeau", error));
      }
    );
  }
}

export function giftPurchase(gift, amount, event, callback) {
  return function (dispatch) {
    return container.getApiClient().gifts().purchase(
      gift,
      amount,
      event,
      function (gift) {
        dispatch({
          type: GIFT_PURCHASE,
          payload: gift,
        });
        callback();
      },
      function (error) {
        dispatch(showError("Erreur lors de la demande d'achat du cadeau", error));
      }
    );
  }
}

export function giftPurchaseCancel(gift) {
  return function (dispatch) {
    return container.getApiClient().gifts().purchaseCancel(
      gift,
      function (gift) {
        dispatch({
          type: GIFT_PURCHASE_CANCEL,
          payload: gift,
        });
      },
      function (error) {
        dispatch(showError("Erreur lors de l'annulation de l'achat du cadeau", error));
      }
    );
  }
}

/**
 * @param {string} name
 * @param {string} description
 * @param {Event} event
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): *}
 */
export function giftCreate(name, description, event, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().gifts().create(
      name,
      description,
      event,
      function (gift) {
        if (callback) {
          callback(gift);
        }
        dispatch({
          type: GIFT_CREATED,
          payload: gift,
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Erreur lors de la création du cadeau", error));
      }
    );
  }
}

/**
 * @param {gift} gift
 * @param {?string} name
 * @param {?string} description
 * @param {?string} observedPrice
 * @param {?boolean} requireContribution
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): *}
 */
export function giftUpdate(
  gift,
  name = undefined,
  description = undefined,
  observedPrice = undefined,
  requireContribution = undefined,
  callback = null,
  callbackError = null
) {
  return function (dispatch) {
    return container.getApiClient().gifts().update(
      gift,
      name,
      description,
      observedPrice,
      requireContribution,
      function (gift) {
        if (callback) {
          callback(gift);
        }
        dispatch({
          type: GIFT_UPDATED,
          payload: gift,
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Erreur lors de la mise à jour du cadeau", error));
      }
    );
  }
}

/**
 * @param {gift} gift
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): *}
 */
export function giftDelete(gift, callback = null, callbackError = null) {
  return function (dispatch) {
    let giftId = gift.getId();
    return container.getApiClient().gifts().delete(
      gift,
      function () {
        if (callback) {
          callback(giftId);
        }
        dispatch({
          type: GIFT_DELETED,
          payload: giftId,
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de supprimer le cadeau", error));
      }
    );
  }
}

/**
 * @param {Gift} gift
 * @param {Event} event
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): *}
 */
export function bindToEvent(gift, event, callback = null, callbackError = null) {
  return function (dispatch) {
    let giftId = gift.getId();
    let eventId = event.getId();
    return container.getApiClient().gifts().bindToEvent(
      gift,
      event,
      function () {
        if (callback) {
          callback(gift, event);
        }
        dispatch({
          type: GIFT_EVENT_BOUND,
          payload: {
            giftId, eventId
          },
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de lier ce cadeau et cet événement", error));
      }
    );
  }
}

/**
 * @param {Gift} gift
 * @param {boolean} requireContribution
 * @param {function} callback
 * @param {function} callbackError
 * @return {function(*): *}
 */
export function giftSetRequireContribution(gift, requireContribution, callback = null, callbackError = null) {
  return function (dispatch) {
    return container.getApiClient().gifts().setRequireContribution(
      gift,
      requireContribution,
      function (gift) {
        if (callback) {
          callback(gift);
        }
        dispatch({
          type: GIFT_UPDATED,
          payload: gift,
        });
      },
      function (error) {
        if (callbackError) {
          callbackError(error)
        }
        dispatch(showError("Impossible de mettre à jour le cadeau", error));
      }
    );
  }
}
