import React from "react";
import {connect} from "react-redux";
import {showError, updatePassword} from "../../../actions";
import {Field, reduxForm} from "redux-form";
import {Button, Form, Header, Message} from "semantic-ui-react";

class UpdatePassword extends React.Component
{
  constructor(props, context) {
    super(props, context);
    this.state = {
      messageAck: undefined,
    };
  }

  handleSubmit = (values) => {
    if (values.password.length < 8) {
      this.props.showError("Le nouveau mot-de-passe doit faire au moins 8 charactères");
      return;
    }
    if (values.password !== values.passwordCheck) {
      this.props.showError("Les mots-de-passes sont différents");
      return;
    }

    this.props.updatePassword(
      encodeURI(values.password),
      function () {
        this.setState({
          messageAck: "Votre mot-de-passe a été mis à jour."
        });
      }.bind(this)
    );
  };

  render() {
    return (
      <>
        <Header>Changer de mot-de-passe</Header>
        <p>Remplissez ce formulaire pour choisir un nouveau mot-de-passe.
          <br/>Ce dernier doit comporter au moins 8 caractères.</p>
        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Form.Group widths='equal'>
            <Field
              component={Form.Input}
              type="password"
              label="Nouveau mot-de-passe"
              name="password"
              placeholder="Nouveau mot-de-passe"
              required={true}
            />
            <Field
              component={Form.Input}
              type="password"
              label="Re-saisissez le mot-de-passe"
              name="passwordCheck"
              placeholder="Vérification du mot-de-passe"
              required={true}
            />
          </Form.Group>
          <Button type="submit" secondary>Mettre à jour</Button>
        </Form>
        {this.state.messageAck && <Message success>{this.state.messageAck}</Message>}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // form: state.form.passwordToken
  };
};

export default reduxForm({form: 'passwordUpdate'})(connect(mapStateToProps, {showError, updatePassword})(UpdatePassword));
