import React from "react";
import {Container, Divider} from "semantic-ui-react";

import Router from "./Router";
import Error from "./Common/Error";
import Footer from "./Common/Footer";
import Head from "./Common/Head";
import Status from "./Common/Status";
import GiftModal from "./Pages/Gifts/Modal/Modal";

import 'semantic-ui-css/semantic.min.css';
import 'react-widgets/styles.css';
import './Gms.scss';
import ModalSearch from "./Common/Search/Modal";

const App = () => (
  <Container className={"scrolling"}>
    <Head />
    <Divider hidden />
    <Router />
    <Status />
    <Error />
    <GiftModal />
    <ModalSearch />
    <Footer />
  </Container>
);

export default App;
