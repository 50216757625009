import Recipient from "./Recipient";

class RecipientFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'recipient') {
      throw new Error("Given raw data not fit for recipient DTO");
    }

    return new Recipient(
      data.id,
      data.relationships && data.relationships.gift ? this.DTOBuilder.getRelationship(data.relationships.gift) : null,
      data.relationships && data.relationships.user ? this.DTOBuilder.getRelationship(data.relationships.user) : null,
    );
  }
}

export default RecipientFactory;
