import React, {Component} from 'react'
import {Container, Divider, Grid, Header, Item, Label, Modal} from 'semantic-ui-react'
import {connect} from "react-redux";
import {
  createComment,
  deleteComment,
  giftPurchase,
  giftPurchaseCancel,
  trackGift,
  unTrackGift,
} from "../../../../actions";
import moment from "moment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import styled from "styled-components";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import ActionPurchase from "./ActionPurchase";
import Contribution from "./Contribution";
import GiftName from "./GiftName";
import GiftDescription from "./GiftDescription";
import GiftDelete from "./GiftDelete";
import GiftPriceObserved from "./GiftPriceObserved";
import GiftEvents from "./GiftEvents";

const GiftHeader = styled(Modal.Header)`
  & > p::first-letter {
    text-transform: capitalize;
  }
`;
const GiftInfo = styled.span`
  font-size: smaller;
  float: right;
`;
const ItemHeader = styled(Item.Header)`
  min-width: 2em;
`;
const IconTracking = styled(Icon)`
  margin-top: -0.5em !important;
  font-size: 2rem !important;
`;

class GiftModalContent extends Component {

  handleCommentCreate = (values) => {
    if (values.text) {
      this.props.createComment(this.props.gift, values.text);
    }
  };

  handleCommentDelete = (comment) => {
    this.props.deleteComment(comment.getId());
  };

  handlePurchase = (amount, callback) => {
    this.props.giftPurchase(this.props.gift, amount, this.props.event, callback);
  };

  handlePurchaseDelete = () => {
    this.props.giftPurchaseCancel(this.props.gift);
  };

  handleGiftTrackChange = () => {
    const {gift} = this.props;
    if (gift.isTracked()) {
      this.props.unTrackGift(gift.getId());
    } else {
      this.props.trackGift(gift.getId());
    }
  };

  render() {
    const {gift} = this.props;
    return <>
      <GiftHeader>
        <GiftDelete gift={gift}/>
        <GiftName gift={gift}/>
        <GiftInfo>
          <Icon name="gift"/>
          Créé par <Label>{gift.getCreator().getFirstname()}</Label> le {moment.parseZone(gift.getDateCreation()).format('DD/MM/YYYY à HH:mm')}
          <GiftEvents gift={gift} userID={this.props.auth.userID} />
        </GiftInfo>
      </GiftHeader>
      <Modal.Content image className="modalGiftContent">
        <Container>
          <Grid columns={2} relaxed>
            <Grid.Column>
              <Item.Group>
                <Item>
                  <ItemHeader><Icon name="file alternate" title="Description" /></ItemHeader>
                  <Item.Content verticalAlign='middle' style={{minWidth: 0}}><GiftDescription gift={gift}/></Item.Content>
                </Item>
                <Item>
                  <ItemHeader><Icon name="money bill alternate outline" title="Prix estimé" /></ItemHeader>
                  <Item.Content verticalAlign='middle'><GiftPriceObserved gift={gift}/></Item.Content>
                </Item>
                <Divider horizontal>
                {gift.getPurchaseDate() || gift.getPurchasePrice() ?
                  <Header as='h4' color='red'><Icon name='money'/>Acheté / Prix</Header>
                :
                  <Header as='h4'><Icon name='money'/>Achat</Header>
                }
                </Divider>
                <ActionPurchase gift={gift} userID={this.props.auth.userID}
                                onPurchase={this.handlePurchase}
                                onPurchaseDelete={this.handlePurchaseDelete}
                />
              </Item.Group>
              <Contribution/>
            </Grid.Column>
            <Grid.Column>
              {gift.getComments() &&
              <CommentList comments={gift.getComments()} userID={this.props.auth.userID} handleDelete={this.handleCommentDelete}/>}
              <Divider horizontal><Header as='h4'><Icon name='talk'/>Commenter</Header></Divider>
              <CommentForm onSubmit={this.handleCommentCreate}/>
            </Grid.Column>
          </Grid>
          <Divider vertical>
            <IconTracking size='big' link
                  name={gift.isTracked() ? 'eye' : 'eye slash'}
                  color={gift.isTracked() ? 'blue' : 'grey'}
                  title={gift.isTracked() ? "Ne plus suivre ce cadeau" : "Suivre ce cadeau"}
                  onClick={this.handleGiftTrackChange}
            />
          </Divider>
        </Container>
      </Modal.Content>
    </>;
  }
}

const mapStateToProps = state => {
  return {
    gift: state.gift,
    event: state.event,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createComment: (gift, text) => dispatch(createComment(gift, text)),
    deleteComment: (id) => dispatch(deleteComment(id)),
    trackGift: (id) => dispatch(trackGift(id)),
    unTrackGift: (id) => dispatch(unTrackGift(id)),
    giftPurchase: (gift, amount, event, callback) => dispatch(giftPurchase(gift, amount, event, callback)),
    giftPurchaseCancel: (gift) => dispatch(giftPurchaseCancel(gift)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftModalContent);
