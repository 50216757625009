import Client from './ApiClient/Client';
import {apiRequestStarted, apiRequestEnded} from "./actions/index";

export class Container
{
  /**
   * @param {Config} config
   * @param {Store} store
   */
  constructor(config, store)
  {
    this.store = store;
    this.client = new Client(
      config.apiUrl,
      () => {this.store.dispatch(apiRequestStarted());},
      () => {this.store.dispatch(apiRequestEnded());},
    );
  }

  /** @returns {Client} */
  getApiClient()
  {
    return this.client;
  }

  /** @returns {Store} */
  getStore()
  {
    return this.store;
  }
}
