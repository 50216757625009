import React from "react";
import {Header, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {logout, showError} from "../../actions";

class Error extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: !! this.props.error.message
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.error !== this.props.error && this.props.error.message) {
      this.setState({ open: true });
    }
  }

  handleClose = () => this.setState({ open: false })

  render() {
    const {error} = this.props;

    if (!this.state.open) {
      return "";
    }

    let message = error.message;
    let baseErrors;
    let responseData = error?.exception?.response?.data;
    if (error.exception) {
      console.error(error.exception);
    } else {
      console.error(error);
    }
    if (responseData !== undefined) {
      if (responseData.errors !== undefined) {
        let errors = [];
        for (let err of Object.values(responseData.errors)) {
          errors.push(err.title);
        }
        baseErrors = errors.join(", ")
      } else {
        if (responseData.code === 401 && responseData.message === "Expired JWT Token") {
          this.props.showError("Session expirée. Veuillez vous reconnecter pour continuer.");
          this.props.logout();
          return "";
        }
        baseErrors = `${responseData.code} - ${responseData.message}`
      }
    }

    return (
      <Modal onClose={this.handleClose} open={true} closeIcon={true}>
        <Modal.Header color="red" as={Header}>Erreur</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {message}
            {baseErrors && error.exception &&
            <details>
              {baseErrors}
              <pre>{JSON.stringify(error.exception, null, 2)}</pre>
              {(!!error.exception && <pre>{JSON.stringify(error.exception.response, null, 2)}</pre>)}
            </details>}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {dispatch(logout())},
    showError: (message) => {dispatch(showError(message))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
