import React from "react";
import {Grid} from "semantic-ui-react";

import List from "./Events/List";
import Create from "./Events/Create";
import {connect} from "react-redux";
import {resetError, setTitle, showError} from "../../actions";
import container from "../../bootstrap";
import ManageParticipants from "./Events/ManageParticipants";

class Events extends React.Component {
  state = {
    events: null,
    selectedEvent: null,
  };

  constructor(props) {
    super(props);
    container.getApiClient().users().getEventsCreated(
      function (events) {
        this.props.resetError();
        let data = {};
        for (let event of Object.values(events)) {
          data[event.id] = event;
        }
        this.setState({
          events: data,
        });
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de charger les événements", error);
      }.bind(this)
    );
  }

  updateStateEventAdd(event) {
    this.setState(prevState => {
      let events = Object.assign({}, prevState.events);
      events[event.id] = event;
      return { events };
    })
  }

  updateStateEventRemove(eventId) {
    this.setState(prevState => {
      let events = Object.assign({}, prevState.events);
      delete events[eventId];
      return { events };
    })
  }

  componentDidMount() {
    this.props.setTitle("Gérer les événements");
  }

  handleCreate = (name, date) => {
    container.getApiClient().events().create(
      name,
      date,
      function (event) {
        this.updateStateEventAdd(event);
        this.props.resetError();
      }.bind(this),
      function (error) {
        this.props.showError("Impossible de créer l'événement", error);
      }.bind(this)
    );
  };

  handleDelete = (id) => {
    container.getApiClient().events().delete(
      id,
      function() {
        this.updateStateEventRemove(id);
      }.bind(this),
      function(error) {
        this.props.showError("Impossible de supprimer l'événement", error);
      }.bind(this)
    );
  };

  handleUpdate = (id, name, date) => {
    container.getApiClient().events().update(
      id, name, date,
      function(event) {
        this.updateStateEventAdd(event);
      }.bind(this),
      function(error) {
        this.props.showError("Impossible de mettre à jour l'événement", error);
      }.bind(this)
    );
  };

  handleSelect = (event) => {
    this.setState({
      selectedEvent: event
    });
  };

  render() {
    return (
      <Grid columns={2} relaxed='very'>
        <Grid.Column>
          <List events={this.state.events}
                handleUpdate={this.handleUpdate}
                handleDelete={this.handleDelete}
                handleSelect={this.handleSelect}
          />
        </Grid.Column>
        <Grid.Column>
          <Create handleCreate={this.handleCreate}/>
          <ManageParticipants event={this.state.selectedEvent} />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(null, {setTitle, resetError, showError})(Events);
