import container from "./bootstrap";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import App from "./components/App";

import 'semantic-ui-css/semantic.min.css';
import {BrowserRouter} from "react-router-dom";

render(
    <Provider store={container.getStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ,
  document.getElementById("root")
);
