import React, {Component} from 'react'
import {connect} from "react-redux";
import {giftUpdate} from "../../../../actions";
import {Icon, Input, Button} from "semantic-ui-react";
import { Anchorme } from 'react-anchorme';

class GiftName extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      editFieldShow: false,
      editFieldValue: props.gift.getName()
    };
  }

  resetSate = () => {
    this.setState({
      editFieldShow: false,
      editFieldValue: this.props.gift.getName()
    });
  };

  handleEditUpdate = (event, object) => {
    this.setState({
      editFieldValue: object.value
    });
  };

  handleEditShow = () => {
    this.setState({
      editFieldShow: true
    })
  };

  handleEditCancel = () => {
    this.resetSate();
  };

  handleEditValidate = () => {
    this.props.giftUpdate(
      this.props.gift,
      this.state.editFieldValue,
      (gift) => {
        this.setState({
          editFieldShow: false,
          editFieldValue: gift.getName()
        });
      },
      () => {
        this.resetSate();
      }
    );
  };

  render() {
    const {gift} = this.props;
    const {editFieldShow, editFieldValue} = this.state;

    let iconEdit = null;
    let validate = null;
    let cancel = null;
    let value = gift.getName();

    if (editFieldShow) {
      value = <Input autoFocus icon="pencil" iconPosition="left" size="mini" value={editFieldValue} onChange={this.handleEditUpdate}/>;
      validate = <Button icon="checkmark" inverted color="green" title="Modifier" onClick={this.handleEditValidate}/>;
      cancel = <Button icon="delete" inverted color="red" title="Annuler" onClick={this.handleEditCancel}/>;
    } else if (gift.getCreator().getId() === this.props.auth.userID) {
      iconEdit = <Icon name="pencil" color="blue" title="Modifier le nom du cadeau" link onClick={this.handleEditShow}/>;
    }

    return <><Anchorme target="_blank">{value}</Anchorme> {iconEdit} {validate} {cancel}</>;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    giftUpdate: (gift, name, cb, cbError) => dispatch(giftUpdate(gift, name, undefined, undefined, undefined, cb, cbError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftName);
