import Contribution from "./Contribution";

class ContributionFactory
{
  constructor(DTOBuilder) {
    this.DTOBuilder = DTOBuilder;
  }

  build(data) {
    if (data.type !== 'contribution') {
      throw new Error("Given raw data not fit for contribution DTO");
    }

    return new Contribution(
      data.id,
      data.relationships && data.relationships.gift ? this.DTOBuilder.getRelationship(data.relationships.gift) : null,
      data.relationships && data.relationships.user ? this.DTOBuilder.getRelationship(data.relationships.user) : null,
      data.attributes.accepted,
      data.attributes.amount,
      data.attributes.collected,
      data.attributes.date,
    );
  }
}

export default ContributionFactory;
